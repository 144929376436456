import { getDashboard } from '@/api/userApi.js';
import { updateSysComLogAndDe } from '@/api/companyApi.js'
export const mixins1 = {
  data() {
    return {
      imgList: [],
      account: { total: '', used: '' },
      menuInfos: [],
      accountMenuList: [],
    }
  },
  created() { },
  methods: {
    //格式化数字，逗号分割
    NumFormat(num) {
      if (!num) return '0.00'
      num = num.toFixed(2)
      var intPart = Math.trunc(num) // 获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
      var floatPart = '.00' // 预定义小数部分
      var value2Array = num.split('.')
      // =2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString() // 拿到小数部分
        if (floatPart.length === 1) {
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    },
    //echarts 数字格式
    filter(num) {
      const newNum = parseInt(num)
      if (newNum) {
        if ((num + '').trim() == '') {
          return ''
        }
        if (String(num).indexOf(',') == -1) {
          num = parseFloat(num)
          num = num.toFixed(2)
          num = parseFloat(num)
          num = num.toLocaleString()
        } else {
          num = num.replace(/,/gi, '')
          num = parseFloat(num).toLocaleString()
        }
      }
      return num
    },
    //获取本地图片路径
    getCountryImgUrl(countryCode) {
      let result = []
      let list = JSON.parse(localStorage.getItem('countryList'))//this.$store.state.countryImgList
      list.forEach(item => {
        item.countryList.forEach(it => {
          if (it.countryCode === countryCode) {
            result.push(it)
          }
        })
      })
      if (result.length > 0) {
        let imgUrl = result[1] && result[1].urlBanner ? result[1].urlBanner : ''
        let arr = imgUrl.split('/static')[1]
        return arr
      }
      else {
        return '/imagev2/undefined.png'
      }
    },
    // 获取国家
    getCountry(countryCode) {
      
      let result1 = []

      let list1 = JSON.parse(localStorage.getItem('countryList'))//this.$store.state.countryImgList
      list1.forEach(item => {
        item.countryList.forEach(it => {
          if (it.countryCode === countryCode) {
            result1.push(it)
          }
        })
      })

      let con = result1[1] && result1[1].nameEn ? result1[1].nameEn : ''
      let country = con
      return country
    },
    // 获取code

    // millageToNumber(number, decimals = 0, zero_format = '-') {
    //   if (!number || number === zero_format) return 0;
    //   if (isString(number)) {
    //     // 替换非数字字符串
    //     number = replaceToNumberStr(number);
    //     // 自动补全数字
    //     number = toFixedNumber(number, decimals);
    //   }
    //   return number;
    // }

    //Account
    getAccountMsg() {
      // getDashboard().then(result => {
      //   if (result && result.code) {
      //     let data = result.data;
      //     this.account.total = data.totalUsage;
      //     this.account.used = data.usedUsage;
      //     let LoginglanceType = ''
      //     let UsagelistType = ''
      //     let OrderlistType = ''
      //     let RechargelistType = ''
      //     let loginDurationType = ''
      //     let menuList = data.roleInfos[0].sysMenuInfos
      //     menuList.forEach(item => {
      //       if (item.title == "Login at a glance") {
      //         LoginglanceType = item.type
      //       }
      //       else if (item.title == "Usage list") {
      //         UsagelistType = item.type
      //       }
      //       else if (item.title == "Order List") {
      //         OrderlistType = item.type
      //       }
      //       else if (item.title == "Recharge list") {
      //         RechargelistType = item.type
      //       }
      //       else if (item.title == "List of login duration and times") {
      //         loginDurationType = item.type
      //       }
      //       else {
      //         this.menuInfos.push(item)
      //       }
      //     });
      //     let that = this
      //     this.$nextTick(() => {
      //       that.getMenu()
      //     })
      //   }
      // });
    },
    getMenu() {

      let list = []
      list = this.menuInfos;

      let that = this
      var num1 = ''
      var num2 = ''
      var num3 = ''
      let accArr = []
      let mytag = []
      let billi = []
      let menuList = []
      if (list.length < 15) {

        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let title = item.title.replace(/\s/g, '').toLowerCase()

          if (title == 'dashboard') {
            let obj = {
              id: item.id,
              name: 'Dashboard',
              url: '/account/dashboard',
              type: item.type,
              img: require('../assets/img/user-icons/10.png')
            }
            menuList.push(obj);
          }
          else if (title == 'addcredit') {
            let obj = {
              id: item.id,
              name: 'Add Credit',
              url: '/account/addCredit',
              type: item.type,
              img: require('../assets/img/user-icons/15.png')
            }
            menuList.push(obj);
          }
          else if (title == 'mydatabase') {
            let obj = {
              id: item.id,
              name: 'My Database',
              url: '/account/myDatabase',
              type: item.type,
              img: require('../assets/img/user-icons/9.png')
            }
            menuList.push(obj);
          }
          else if (title == 'portfolio') {
            let obj = {
              id: item.id,
              name: 'Portfolio',
              url: '/account/portfolio',
              type: item.type,
              img: require('../assets/img/user-icons/8.png')
            }
            num1 = index
            menuList.push(obj);
          }
          else if (title == 'mytag') {
            let obj = {
              id: item.id,
              name: 'My tag',
              url: '/account/myTag',
              type: item.type,

            }
            mytag.push(obj);
            menuList.push('');
          }
          else if (title == 'account') {
            let obj = {
              id: item.id,
              name: 'Account',
              url: '/account/accountSettings',
              type: item.type,
              img: require('../assets/img/user-icons/7.png')
            }
            num2 = index
            menuList.push(obj);
          }
          else if (title == 'contactinformation') {
            let obj = {
              id: item.id,
              name: 'Contact Information',
              url: '/account/contactInformation',
              type: item.type
            }
            accArr.push(obj);
            menuList.push('');
          }
          else if (title == 'alternatecontacts') {
            let obj = {
              id: item.id,
              name: 'Alternate Contacts',
              url: '/account/alternateContacts',
              type: item.type,

            }
            accArr.push(obj);
            menuList.push('');
          }
          else if (title == 'organization') {
            let obj = {
              id: item.id,
              name: 'Organization',
              url: '/account/organization',
              type: item.type,
              img: require('../assets/img/user-icons/5.png')
            }
            menuList.push(obj);
          }
          else if (title == 'quota') {
            let obj = {
              id: item.id,
              name: 'Quota',
              url: '/account/quota',
              type: item.type,
              img: require('../assets/img/user-icons/6.png')
            }
            menuList.push(obj);
          }
          else if (title == 'billing') {
            let obj = {
              id: item.id,
              name: 'Billing',
              url: '/account/billing',
              type: item.type,
              img: require('../assets/img/user-icons/4.png')
            }
            let obj1 = {
              id: item.id + 1,
              name: 'Billing Details',
              url: '/account/billinginformation',
              type: item.type,
              img: require('../assets/img/user-icons/4.png')
            }
            let obj2 = {
              id: item.id + 2,
              name: 'Billing Information',
              url: '/account/billinginformationtwo',
              type: item.type,
              img: require('../assets/img/user-icons/4.png')
            }
            num3 = index
            billi.push(obj1)
            billi.push(obj2)
            menuList.push(obj);
          }
          else if (title == 'faq') {
            let obj = {
              id: item.id,
              name: 'FAQ',
              url: '/account/faq',
              type: item.type,
              img: require('../assets/img/user-icons/3.png')
            }
            menuList.push(obj);
          }
        }

        menuList[num1].result = mytag
        menuList[num2].result = accArr
        menuList[num3].result = billi

        [menuList[0], menuList[1]] = [menuList[1], menuList[0]]

        that.accountMenuList = menuList.filter(item => item !== '')
        // 
      }


      // });

    },
    message() {
      this.$message({
        message: 'No permission, please purchase',
        type: "success",

      })
      let that = this
      let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

      let routeData = ''
      setTimeout(function () {
        if (userinfo.type == '3') {
          routeData = that.$router.resolve({
            path: '/account/quota',
          });
        } else {
          routeData = that.$router.resolve({
            path: '/account/addCredit',
          });
        }

        window.open(routeData.href, '_blank');
      }, 1000)

    },
    //扣点记录流浪时间
    getliulantime(params1, pagename) {
      let startTime = Date.now()
      const params = {
        // 界面
        aaaId: params1.aaaId,
        // countryCode: from.query.companyCountry,
        pageName: pagename,
        companyName: params1.companyName,
        // 进入界面时间
        startTimed: startTime,
        // gmtCreate:  moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
        // 离开时间
        endTimed: startTime,
        // gmtLeave: new moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
        /**
         * 进入或离开状态
         * enter进入
         * exit 离开
         */
        // type: 'exit',
        // 停留时长
        // duration: standingTime
      }
      updateSysComLogAndDe(params).then(res => {
        //  console.log(res);
      })
    }

  }
}
