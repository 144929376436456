
import {doLogin,thirdLoginGl,thirdLoginAp} from '../../api/api'
import {
  Message
} from 'element-ui'
const state = {
    // 初始值
    token: '',
    id: '',
    userCode:'',
    userInfo:''
}

const getters = {
    token(state){
        return state.token
    },
    id(state){
        return state.id
    },
    userCode(state){
        return state.userCode
    },
    userInfo(state){
      return state.userInfo
  }
}

const mutations = {
    settoken(state,token){
     state.token=token
    },
    setid(state,id){
        state.id=id
    },
    setuserCode(state,userCode){
        state.userCode=userCode
    },
    setuserInfo(state,userInfo){
      state.userInfo=userInfo
  }
}

const actions = {
    LoginSubmit ({ commit },params) {
        return new Promise((resolve, reject) => {
  
            doLogin(params).then(res => {
       
              if (res.code === 200) {
              
                commit('settoken', res.data.token)
                commit('setid',  res.data.userInfo.id)
                commit('setuserCode', res.data.userInfo.userCode)
                commit('setuserInfo', res.data.userInfo)
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                // sessionStorage.setItem('token', res.data.token)
                localStorage.setItem('token', res.data.token)
                // 存第一份点击的时间
                sessionStorage.setItem('lastClickTime', new Date().getTime())
                resolve(res)
              } else {
                // Message({
                //   message: res.msg,
                //   type: 'error'
                // })
                resolve(res)
              }
            }).catch(err => {
              // reject(err)
            })
          })
        }, 
        LoginSubmitGoole ({ commit },params) {
          return new Promise((resolve, reject) => {
    
            thirdLoginGl(params).then(res => {
         
                if (res.code === 200) {
                
                  commit('settoken', res.data.token)
                  commit('setid',  res.data.userInfo.id)
                  commit('setuserCode', res.data.userInfo.userCode)
                  commit('setuserInfo', res.data.userInfo)
                  sessionStorage.setItem('token', res.data.token)
                  sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                  localStorage.setItem('userInfo', JSON.stringify(res.data))
                  // sessionStorage.setItem('token', res.data.token)
                  localStorage.setItem('token', res.data.token)
                  // 存第一份点击的时间
                  sessionStorage.setItem('lastClickTime', new Date().getTime())
                  resolve(res)
                } else {
                  Message({
                    message: res.msg,
                    type: 'error'
                  })
                  resolve(res)
                }
              }).catch(err => {
                // reject(err)
              })
            })
          }, 
          LoginSubmitApple ({ commit },params) {
            return new Promise((resolve, reject) => {
      
              thirdLoginAp(params).then(res => {
           
                  if (res.code === 200) {
                  
                    commit('settoken', res.data.token)
                    commit('setid',  res.data.userInfo.id)
                    commit('setuserCode', res.data.userInfo.userCode)
                    commit('setuserInfo', res.data.userInfo)
                    sessionStorage.setItem('token', res.data.token)
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    // sessionStorage.setItem('token', res.data.token)
                    localStorage.setItem('token', res.data.token)
                    // 存第一份点击的时间
                    sessionStorage.setItem('lastClickTime', new Date().getTime())
                    resolve(res)
                  } else {
                    Message({
                      message: res.msg,
                      type: 'error'
                    })
                    resolve(res)
                  }
                }).catch(err => {
                  // reject(err)
                })
              })
            }, 

}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}