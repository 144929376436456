// 公司功能模块/国家功能模块

import { Loading } from 'element-ui'
import axios from './request'
//定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./;
const phone =mobil.test(currentUrl) 

const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8066/csi'//new-测试
let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'https://www.csibizsearch.com/csi'//生产
// let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
// if(phone){
//   api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
// }

export const getoptions = params => {
  return axios({
    isLoading: true,
    url: api + '/company/filters',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const getoptionsProduct= params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/search/advanced/optionsProduct',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
     
    }
  })
}
//收藏-是否收藏
export const getIsCollect = params => {
  return axios.post(api + '/gleagle/collection/getCollection' , params)
}
//Company Snapshot

export const getCompanySnap = params => {
  return axios.post(api + '/backgroud/getCompanySnapshot', params)
}
// hscode获取产品详情
export const getProductProfile = params => {
  return axios.post(api + '/trade/getProductProfile', params)
}
// 获取证信代码
export const getCompanySimpleInfo = params => {
  return axios.get(api + '/creditService/getCompanySimpleInfo?' + params)
}
//talbe栏_v2
export const getCompanyDetailMenuList = params => {
  // return axios.get(api + '/gleagle/csiv4/getTableTagList?' + params)

  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getTableTagList?'+params,
    method: 'GET',
    
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


//Faq
//获取问题集问题
export const getQuestionList = params => {
  return axios.get(api + '/gleagle/csiv4/getQuestionList?' + params)
}
//获取问题集答案
export const getAnswerList = params => {
  return axios.get(api + '/gleagle/csiv4/getAnswer?' + params)
}
//获取相似公司
export const getSimilarCompanies = params => {
  return axios.get(api + '/gleagle/csiv4/getSimilarCompanies?' + params)
}

//公司简介
export const getCompanyProfile = params => {
  return axios.post(api + '/backgroud/getCompanyProfile' , params)
}

//公司历史
export const getHistory = params => {
  return axios.get(api + '/gleagle/csiv4/getHistory?' + params)
}

//公司管理层
export const getManagement = params => {
  return axios.get(api + '/gleagle/csiv4/getManagement?' + params)
}
//联系方式
export const getContactInfo = params => {
  return axios.get(api + '/gleagle/csiv4/getContactInfo?' + params)
}

//获取进口状况 getImportorExportTransaction

export const getImportorExportTransaction = params => {
  return axios({
    url: api + '/businessActivities/getImportorExportTransaction',
    method: 'GET',
    params
  })
}
//获取某个模块下的所有区域权限
export const getPartPrivilege = params => {
  return axios({
    url: api + '/tab/getPartPrivilege',
    method: 'GET',
    params
  })
}
export const getImportorExportRankBySC = params => {
  return axios({
    url: api + '/businessActivities/getImportorExportRankBySC',
    method: 'GET',
    params
  })
}
export const showImportorExportForSCRanks = params => {
  return axios({
    url: api + '/businessActivities/showImportorExportForSCRanks',
    method: 'GET',
    params
  })
}
export const getImportOrExportTradeByContinent = params => {
  return axios({
    url: api + '/businessActivities/getImportOrExportTradeByContinent',
    method: 'GET',
    params
  })
}
export const getImportOrExportProductTrade = params => {
  return axios({
    url: api + '/businessActivities/getImportOrExportProductTrade',
    method: 'GET',
    params
  })
}

export const getImportExportALl = params => {
  return axios({
    url: api + '/businessActivities/getImportExport',
    method: 'GET',
    params
  })
}
//获取进口状况 getImportorExportTransaction

export const getbusinessActivitiesDetails = params => {
  return axios({
    url: api + '/businessActivities/details',
    method: 'GET',
    params
  })
}
export const showNationalTradeEnterprises = params => {
  return axios({
    url: api + '/trade/country/showNationalTradeEnterprises',
    method: 'GET',
    params
  })
}
export const showTradeDataFromOtherCountries = params => {
  return axios({
    url: api + '/trade/country/showTradeDataFromOtherCountries',
    method: 'GET',
    params
  })
}
export const getProductChainSanctions = params => {
  return axios.post(api + '/trade/getProductChainSanctions' , params)
}

//获取出口状况
export const getImportOrExport = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getImportorExport?'+params,
    method: 'GET',

  })
  // return axios.get(api + '/gleagle/csiv4/getImportorExport?' + params)
}

// 获取edit配置列表
//获取供应链数据
export const getEditqueryList = params => {
  return axios({
    isLoading: true,
    url: api + '/tools/edit/query?' + params,
    method: 'GET',

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
//获取贸易公司tradeTrends模块

export const showTradeTrendsOfTargetCountries = params => {
  return axios({
    isLoading: true,
    url: api + '/trade/country/showTradeTrendsOfTargetCountries',
    method: 'GET',
    params

  })
}
//获取贸易公司tradeTrends模块
export const showCountryRatings = params => {
  return axios({
    isLoading: true,
    url: api + '/trade/country/showCountryRatings',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
export const tradeTrends = params => {
  return axios({
    isLoading: true,
    url: api + '/tradeForCompany/tradeTrends',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
export const showCountryTradeData = params => {
  return axios({
    isLoading: true,
    url: api + '/trade/country/showCountryTradeData',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
export const showTradeDataTrends = params => {
  return axios({
    isLoading: true,
    url: api + '/trade/country/showTradeDataTrends',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
// 获取产品供应链图
export const getProductSupplyChain = params => {
  return axios.post(api + '/trade/getProductSupplyChain' , params,)
}
// 获取产品贸易趋势
export const getProductTradeTrendsForm = params => {
  return axios.post(api + '/trade/getProductTradeTrendsForm' , params,)
}
//获取公司制裁名单
export const getPublications = params => {
  return axios.get(api + '/gleagle/csiv4/getPublications?' + params)
}
//获取公司新闻
export const getNewsMedia = params => {
  return axios.get(api + '/gleagle/csiv4/getNewsMedia?' + params)
}

//企业对比
// { headers: { 'Content-Type': "application/json",istrue: "genxin"
export const getCompanyCompare = params => {
  return axios.get(api + '/companyCompare/getData?' + params)
}
//企业更新
// { headers: { 'Content-Type': "application/json",istrue: "genxin"}}
export const getUpdateDataCompare = params => {
  return axios.post(api + '/creditService/updateData' , params,)
}
// 数据反馈
export const getUpdatdataFeedback = params => {
  return axios.put(api + '/gleagle/creditService/dataFeedback' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
}
//文件上传
export const uploadFile = params => {
  return axios.post(api + '/file/ossfile/upload', params, { type: "upload" })
}
// 
export const getProductCountryMap = params => {
  return axios.post(api + '/trade/getProductCountryMap', params, )
}
export const getProductCountryMapDropDown = params => {
  return axios.post(api + '/trade/getProductCountryMapDropDown', params, )
}


export const getCompanyCountryMap = params => {
  return axios({
    isLoading: true,
    url: api + '/tradeForCompany/getTradeMap',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}

export const getTradeMapTrendData = params => {
  return axios({
    isLoading: true,
    url: api + '/trade/country/getTradeMapTrendData',
    method: 'GET',
    params

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
//文件删除
export const deleteFile = params => {
  return axios.delete(api + '/file/ossfile/delete', {params:params})
}
//企业报错
export const getReportError = params => {
  return axios.post(api + '/creditService/reportError' , params,)
}

// export const getReportError = params => {
//   return axios.put(api + '/gleagle/creditService/reportError' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
// }

//国家集合
export const getCountryCollection = params => {
  return axios.get(api + '/common/countryList?'+params)
}
export const getUserSimpleInfo = params => {
  return axios({
    url: api + '/user/getUserSimpleInfo',
    method: 'GET',
    params
  })
}
export const getCommonPrivilegeModule = params => {
  return axios({
    url: api + '/privilegeModules/getCommonPrivilegeModule',
    method: 'GET',
    params
  })
}
// pc获课用户列表
export const getAnswersHead = params => {
  return axios({
    url: api + '/findPartnerPc/getAnswersHead',
    method: 'GET',
    params
  })
}
// pc删除用户的答题卡
export const delAnswersHead = params => {
  return axios({
    url: api + '/findPartnerPc/delAnswersHead',
    method: 'GET',
    params
  })
}
// 获取获客最右侧详情数据
export const getCompanyIndustryInfo = params => {
  return axios({
    url: api + '/findPartnerPc/getCompanyIndustryInfo',
    method: 'GET',
    params
  })
}
// 获取获客最右侧详情数据
export const getCompanyDetail = params => {
  return axios({
    url: api + '/findPartnerPc/getCompanyDetail',
    method: 'GET',
    params
  })
}
export const getCompanyLabelList = params => {
  return axios({
    url: api + '/company/getCompanyLabelList',
    method: 'GET',
    params
  })
}
//获取充值画面
export const getList = params => {
  return axios.post(api + '/gleagle/product/getList', params)
}
//获取价格
export const getPrice = params => {
  return axios.post(api + '/gleagle/product/getPrice', params,{isLoading:true})
}
//用户购买报告
export const ordersAdd = data => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/orders/add',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  
  // return axios.post(api + '/gleagle/orders/add', params)
}
// Company-BusinessActivitie
//获取公司业务概要
export const getCompanyOperating = params => {
  return axios({
    url: api + '/businessActivities/getCompanyOperating',
    method: 'GET',
    params
  })
}
// 添加产品
export const addProducts = data => {
  return axios({
    url: api + '/businessActivities/addProducts',
    method: 'POST',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   istrue: "zhifu"
    // }
  })
}

//编辑业务列表或产品&服务信息
export const editCompanyPartialInfo = data => {
  return axios({
    url: api + '/businessActivities/editCompanyPartialInfo',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      istrue: "zhifu"
    }
  })
}
// export const editCompanyPartialInfo = params => {
//   return axios.post(api + '/businessActivities/editCompanyPartialInfo', params,)
// }
//获取PublicClientKey
export const getPublicClientKey = params => {
  return axios.post(api + '/pay/getPublicClientKey', params)
}
//获取公司业务概要
export const getCompanyLocations = params => {
  return axios({
    url: api + '/businessActivities/getCompanyLocations',
    method: 'GET',
    params
  })
}
//接口名称：添加公司相关地址
// export const addAddressInfo = params => {
//   return axios.get(api + '/businessActivities/addAddressInfo', params)
// }
export const addAddressInfo = data => {
  return axios({
    url: api + '/businessActivities/addAddressInfo',
    method: 'post',
    data
  })
}
export const editAddressInfo = data => {
  return axios({
    url: api + '/businessActivities/editAddressInfo',
    method: 'post',
    data
  })
}
//获取公司业务概要
export const getCompanyIndustry = params => {
  return axios({
    url: api + '/businessActivities/getCompanyIndustry',
    method: 'GET',
    params
  })
}

//获取行业统计信息
export const getIndustryStatics = params => {
  return axios({
    url: api + '/businessActivities/getIndustryStatics',
    method: 'GET',
    params
  })
}
//获取行业统计点状图
export const getIndustryDotPlot = params => {
  return axios({
    url: api + '/businessActivities/getIndustryDotPlot',
    method: 'GET',
    params
  })
}
//获取公司进出口统计数据
export const getImportExport = params => {
  return axios({
    url: api + '/businessActivities/getImportExport',
    method: 'GET',
    params
  })
}
//获取公司进出口具体条件排行数据
export const getImportorExportRank = params => {
  return axios({
    url: api + '/businessActivities/getImportorExportRank',
    method: 'GET',
    params
  })
}
//获取公司进出口具体条件排行数据
export const getImportorExportShipWayBill = params => {
  return axios({
    url: api + '/businessActivities/getImportorExportShipWayBill',
    method: 'GET',
    params
  })
}
//根据公司查产品根据产品查港
export const getRelatiedInfo = params => {
  return axios({
    url: api + '/businessActivities/getRelatiedInfo',
    method: 'GET',
    params
  })
}
//获取船运信息
export const getImportorExportShipInfo = params => {
  return axios({
    url: api + '/businessActivities/getImportorExportShipInfo',
    method: 'GET',
    params
  })
}
//进出口供应链
export const getSupplyChain = params => {
  return axios({
    url: api + '/businessActivities/getSupplyChain',
    method: 'GET',
    params
  })
}

//获取进口或出口Transaction（交易信息）
export const getImportorExportdetails = params => {
  return axios({
    url: api + '/businessActivities/details',
    method: 'GET',
    params
  })
}
// //支付接口
// export const cardAuthorize = params => {
//   return axios.post(api + '/gleagle/pay/cardAuthorize', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",istrue: "zhifu"} },)
// }
export const cardAuthorize = params => {
  // return axios.post(api + '/gleagle/pay/cardAuthorize', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",istrue: "zhifu"} },)
  return axios.post(api + '/pay/cardAuthorize', params)
}
// 新增充值几率
export const saveUserRecharges = params => {
  // return axios.post(api + '/gleagle/pay/cardAuthorize', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",istrue: "zhifu"} },)
  return axios.post(api + '/pay/saveUserRecharges', params)
}
//国家详情
export const getCountryData = params => {
  return axios.get(api + '/gleagle/csiv4/getCountryData?'+ params)
}
//国家信息相关
export const searchCountryInfo = params => {
  return axios.post(api + '/gleagle/company/searchCountryInfo', params,)
}
//国家信息详情
export const searchCountryInfoWithExcel = data => {
  return axios({
    url: api + '/gleagle/company/searchCountryInfoWithExcel',
    method: 'POST',
    data,
    responseType: 'arraybuffer'
    // responseType: 'blob'
    // url:post(, params)
  })
}
//国家相关信息-图表
export const searchCountryInfoChart = params => {
  return axios.post(api + '/gleagle/company/searchCountryInfoChart', params)
}

//Ownership
//所有权
export const getOwnership = data => {
  return axios({
    url: api + '/backgroud/getCompanyOwnership',
    method: 'post',
    data
  })
}
// 股东
export const getCompanyInvestment = data => {
  return axios({
    url: api + '/backgroud/getCompanyInvestment',
    method: 'post',
    data
  })
}

// 股东九宫格
export const getShareholderSummary = data => {
  return axios({
    url: api + '/backgroud/getShareholderSummary',
    method: 'post',
    data
  })
}
// 关联公司
export const getCompanies = data => {
  return axios({
    url: api + '/backgroud/getCompanyRelated',
    method: 'post',
    data
  })
}

//分支机构
export const getCompanyBranch = data => {
  return axios({
    url: api + '/backgroud/getCompanyBranch',
    method: 'post',
    data
  })
}
// 管理人员
export const getCompanyManagement = data => {
  return axios({
    url: api + '/backgroud/getCompanyManagement',
    method: 'post',
    data
  })
}
// 查询更多邮箱
export const searchMoreEmail = data => {
  return axios({
    url: api + '/backgroud/searchMoreEmail',
    method: 'post',
    data
  })
}
// 管理人员过滤
export const getManagementFilter = data => {
  return axios({
    url: api + '/backgroud/getManagementFilter',
    method: 'post',
    data
  })
}
//关联公司九宫格
export const getRelatedSummary = data => {
  return axios({
    url: api + '/backgroud/getRelatedSummary',
    method: 'post',
    data
  })
}
//Ai
export const getAiQuestion = params => {
  return axios({
    isLoading: true,
    url: api + '/ai/question',
    method: 'GET',
    params
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   istrue: "zhifu"
    // }
  })
}
//管理公司九宫格
export const getManagementSummary = data => {
  return axios({
    url: api + '/backgroud/getManagementSummary',
    method: 'post',
    data
  })
}
// 管理层人物简介
export const getPersonProfile = data => {
  return axios({
    url: api + '/backgroud/getPersonProfile',
    method: 'post',
    data
  })
}
// 管理层人物关联公司表单
export const getPersonRelateCompany = data => {
  return axios({
    url: api + '/backgroud/getPersonRelateCompany',
    method: 'post',
    data
  })
}
// 获取新闻
export const getNews = params => {
  return axios({
    url: api + '/publications/getNews',
    method: 'GET',
    params
  })
}
export const getSanctions = params => {
  return axios({
    url: api + '/publications/getSanctions',
    method: 'GET',
    params
  })
}
// 贸易公司Country模块
export const getCountries = params => {
  return axios({
    url: api + '/tradeForCompany/getCountries',
    method: 'GET',
    params
  })
}
// 贸易公司Country模块年份
export const getCountriesYearMonth = params => {
  return axios({
    url: api + '/tradeForCompany/getCountriesYear',
    method: 'GET',
    params
  })
}
// 贸易公司Country模块年份
export const getProductsYearMonth = params => {
  return axios({
    url: api + '/tradeForCompany/getProductsYear',
    method: 'GET',
    params
  })
}
// 贸易公司Country模块年份
export const getPartnersYearMonth = params => {
  return axios({
    url: api + '/tradeForCompany/getPartnersYear',
    method: 'GET',
    params
  })
}
// 贸易国家Country模块
export const showTopCountryTradeData = params => {
  return axios({
    url: api + '/trade/country/showTopCountryTradeData',
    method: 'GET',
    params
  })
}
// Partners栏
export const getPartners = params => {
  return axios({
    url: api + '/tradeForCompany/getPartners',
    method: 'GET',
    params
  })
}
// Products栏
export const getProducts = params => {
  return axios({
    url: api + '/tradeForCompany/getProducts',
    method: 'GET',
    params
  })
}
// 国家
export const showImportAndExportProductsOfTop10 = params => {
  return axios({
    url: api + '/trade/country/showImportAndExportProductsOfTop10',
    method: 'GET',
    params
  })
}
// 贸易的Suppliers
export const getProductRankingSb= data => {
  return axios({
    url: api + '/trade/getProductRankingSb',
    method: 'post',
    data
  })
}
// 新闻高级筛选条件(获取source列表)
export const getfilterConditions = params => {
  return axios({
    url: api + '/publications/news/filterConditions',
    method: 'GET',
    params
  })
}
// 行业内容
//新闻检索条件
export const getPdfLink= params => {
  return axios({
    url: api + '/publications/news/getPdfLink',
    method: 'GET',
    params,
  
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//获客公司列表的供应链列表
export const getsearchExport= params => {
  return axios({
    url: api + '/findPartnerPc/trace/search',
    method: 'GET',
    params,
  
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//获取获客模块权限配置
export const getHuokePartPrivilege= params => {
  return axios({
    url: api + '/findPartnerPc/getPrivilege',
    method: 'GET',
    params,
  
  })
  // return axios.get(api + '/gleagle/search' + params)
}
// 运营内容
export const getBusinessOperations = params => {
  return axios({
    url: api + '/gleagle/csiv4/getBusinessOperations',
    method: 'GET',
    params
  })
}
// 行业内容
export const getIndustry = params => {
  return axios({
    url: api + '/gleagle/csiv4/getIndustry',
    method: 'GET',
    params
  })
}
//公司业绩和公司信用评估
export const getPerformance = params => {

  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getPerformance',
    method: 'GET',
    params
  })
}
// 关联公司财务数据
export const getrelatedCompanylist= data => {
  return axios({
    url: api + '/performance/getFinancialsRelateCompany',
    method: 'post',
    data
  })
}
// 财务九宫格
export const getPerformanceSummary= data => {
  return axios({
    url: api + '/performance/getPerformanceSummary',
    method: 'post',
    data
  })
}
// 行业研究比较表单
export const getIndustryResearchComparison= data => {
  return axios({
    url: api + '/performance/getIndustryResearchComparison',
    method: 'post',
    data
  })
}
// 观测项目表单
export const getObservationProject= data => {
  return axios({
    url: api + '/performance/getObservationProject',
    method: 'post',
    data
  })
}
export const ratingRanking= data => {
  return axios({
    url: api + '/market/ratingRanking',
    method: 'post',
    data
  })
}
// markest
export const getProductRankingDropDownOd= data => {
  return axios({
    url: api + '/trade/getProductRankingDropDownOd',
    method: 'post',
    data
  })
}
export const getProductRankingDropDownSb= data => {
  return axios({
    url: api + '/trade/getProductRankingDropDownSb',
    method: 'post',
    data
  })
}
export const ratingResearchIndustry= data => {
  return axios({
    url: api + '/market/ratingResearchIndustry',
    method: 'post',
    data
  })
}
export const ratingAreaNumTable= data => {
  return axios({
    url: api + '/market/ratingAreaNumTable',
    method: 'post',
    data
  })
}
export const ratingHistory= data => {
  return axios({
    url: api + '/market/ratingHistory',
    method: 'post',
    data
  })
}
export const ratingNature= data => {
  return axios({
    url: api + '/market/ratingNature',
    method: 'post',
    data
  })
}
export const ratingSize= data => {
  return axios({
    url: api + '/market/ratingSize',
    method: 'post',
    data
  })
}
export const getProductRankingOd= data => {
  return axios({
    url: api + '/trade/getProductRankingOd',
    method: 'post',
    data
  })
}
export const enterpriseOverview= data => {
  return axios({
    url: api + '/market/enterpriseOverview',
    method: 'post',
    data
  })
}
export const financialRatios= data => {
  return axios({
    url: api + '/market/financialRatios',
    method: 'post',
    data
  })
}
export const ratingNormalDesTable= data => {
  return axios({
    url: api + '/market/ratingNormalDesTable',
    method: 'post',
    data
  })
}
// 观测项目表单
export const getAbilityPerformance= data => {
  return axios({
    url: api + '/performance/getAbilityPerformance',
    method: 'post',
    data
  })
}
//公司业绩和公司信用评估
export const getRatingDistribution = params => {

  return axios({
    isLoading: true,
    url: api + '/market/getRatingDistribution',
    method: 'GET',
    params
  })
}
export const getBlockListData = params => {

  return axios({
    isLoading: true,
    url: api + '/market/getBlockListData',
    method: 'GET',
    params
  })
}
//公司业绩和公司信用评估
export const getAttrbuteComparison = params => {

  return axios({
    isLoading: true,
    url: api + '/market/getAttrbuteComparison',
    method: 'GET',
    params
  })
}
//公司业绩和公司信用评估
export const getPerformanceComparison = params => {

  return axios({
    isLoading: true,
    url: api + '/market/getPerformanceComparison',
    method: 'GET',
    params
  })
}
//公司业绩和公司信用评估
export const getMarketAnalysisQuantity = data => {

  return axios({
    isLoading: true,
    url: api + '/market/getMarketAnalysisQuantity',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      istrue: "zhifu"
    }
  })
}
export const getRatingAnalysisNumbyCondition = data => {

  return axios({
    isLoading: true,
    url: api + '/market/getRatingAnalysisNumbyCondition',
    method: 'post',
    data
  })
}
// 获取基础条件
export const getratingAnalysisCurrent = params => {

  return axios({
    isLoading: true,
    url: api + '/market/ratingAnalysisCurrent',
    method: 'GET',
    params
  })
}
// 财务数据
export const getFinancials = data => {
  return axios({
    isLoading: true,
    url: api + '/performance/getFinancialHighlights',
    method: 'post',
    data
  })
}
// 财务数据
export const getCreditAssessment = data => {
  return axios({
    isLoading: true,
    url: api + '/creadit/getCreditAssessment',
    method: 'post',
    data
  })
}
// 股东穿透echartlist
export const getCompanyChart = params => {
  return axios({
    url: api + '/gleagle/csiv4/getCompanyChart',
    method: 'GET',
    params
  })
}
// 股东穿透echartlist2
export const getCompanyCharttwo = data => {
  return axios({
    url: api + '/backgroud/getCompanyShareholderChart',
    method: 'post',
    data
  })
}
export const getCompanyRelatedChart = data => {
  return axios({
    url: api + '/backgroud/getCompanyRelatedChart',
    method: 'post',
    data
  })
}
export const getCompanyBranchChart = data => {
  return axios({
    url: api + '/backgroud/getCompanyBranchChart',
    method: 'post',
    data
  })
}
// 股东穿透echartlist2
export const getCompanyInvestmentChart = data => {
  return axios({
    url: api + '/backgroud/getCompanyInvestmentChart',
    method: 'post',
    data
  })
}

// 公司公告
// 股东穿透echartlist2
export const getLawsuits = params => {
  return axios({
    url: api + '/publications/getLawsuits',
    method: 'GET',
    params
  })
}
//修改核实状态
export const updateContractInfo = params => {
  return axios.get(api + '/gleagle/csiv4/updateEsContract?' + params)
}
// 添加足迹

export const updateSysComLogAndDe = params => {
  return axios.post(api + '/gleagle/csiv4/updateSysComLogAndDe',params)
}
// 贸易导出
export const exportTradeDetail = params =>{
  return axios.get(api + '/gleagle/trade/tradeInformation/excel?' + params,{  isLoading: true})

}
// 获课二次筛选
export const leadsPostFilter = params => {
  return axios.post(api + '/findPartnerPc/leadsPostFilter', params,{  isLoading: true})
}
export const saveLeadsPostFilter = params => {
  return axios.post(api + '/findPartner/saveLeadsPostFilter', params,{  isLoading: true})
}
export const getPartPcPrivilege = params => {
  return axios({
    url: api + '/findPartnerPc/getPrivilege',
    method: 'GET',
    params
  })
}
