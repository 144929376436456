import Vue from "vue";

Vue.filter("NumFormat", function(value) {
    // if (!value) return "0.00";
    if (!value) return "-";
    var intPart = value.toString().split(".")[0]; 
  
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  
    var floatPart = ".00"; 
    var value2Array = value.toString().split(".");
    if (value2Array.length === 2) {
      floatPart = value2Array[1].toString();
  
      if (floatPart.length === 1) {
        return intPartFormat + "." + floatPart + "0";
      } else {
        return intPartFormat + "." + floatPart;
      }
    } else {
      return intPartFormat + floatPart;
    }
  });
  //格式化数字，逗号分割 三位一,
  Vue.filter("FilterNum", function(num) {
      const newNum = parseInt(num)
      if (newNum) {
        if ((num + '').trim() == '') {
          return ''
        }
        if (String(num).indexOf(',') == -1) {
          num = parseFloat(num)
          num = num.toFixed(2)
          num = parseFloat(num)
          num = num.toLocaleString()
        } else {
          num = num.replace(/,/gi, '')
          num = parseFloat(num).toLocaleString()
        }
      }
      return num
    
  });
