import Layout from '../views/layout/index.vue'
import VueRouter from 'vue-router'
const routes = [
    {
        path: '/',
        redirect: '/home'

    },
    {
        path:'/home',
        name:'home',
        component: () => import('../views/home/index.vue'),
    },
    {
      path: '/csiApp',
      name: 'csiApp',
      component: () =>
        import('../views/csiApp/csiApp.vue'),
    },

    {
        path: '/index',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'list',
                component: () => import('../views/company/list.vue'),
                name: 'companyList',
                meta: { title: '企业列表', activeMenu: '/system/role' },
                children: [
                    {
                        path: 'company',
                        name:'companyNoParams',
                        component: () => import('../views/company/list/company.vue'),
                    },{
                        path: 'risk',
                        name:'riskNoParams',
                        component: () => import('../views/company/list/risk.vue'),
                    }
                ]
            },
            {
                path: 'search/list/:search',
                component: () => import('../views/company/list.vue'),
                name: 'companyListSearch',
                meta: { title: '企业列表', activeMenu: '/system/role' },
                children: [
                    {
                        path: 'company',
                        name:'companylistSearch',
                        component: () => import('../views/company/list/company.vue'),
                    },{
                        path: 'risk',
                        name:'risklist',
                        component: () => import('../views/company/list/risk.vue'),
                    }
                ]
            },
            // 获课
            {
                path: 'risk/details/:search',
                component: () => import('../views/company/list.vue'),
                name: 'riskDeatailsP',
                children: [
                    {
                        name: 'riskdetailsC',
                        path: 'riskdetails',
                        component: () => import('../views/company/details/Risk/risk.vue')
                    }
                ]
            },
               // 贸易公司详情
               {
                path: 'Tradecompany/Trade/:id',
                component: () => import('../views/company/TradeDetail.vue'),
                name: 'TradeDetails',
                meta: { title: 'TradeDetails' },
                children: [
                    // background部分
                    {
                        name: 'tradeMap',
                        path: 'tradeMap/map',
                        meta: { title: 'TradeDetails' },
                        component: () => import('../views/company/Trade/tradeMap/map.vue'),
                    },
                    {
                      name: 'supplyChain',
                      path: 'supplyChain/index',
                      meta: { title: 'TradeDetails' },
                      component: () => import('../views/company/Trade/SupplyChain/index.vue'),
                  },
                  {
                    name: 'competitiveness',
                    path: 'competitiveness/index',
                    meta: { title: 'TradeDetails' },
                    component: () => import('../views/company/Trade/Competitiveness/index.vue'),
                },
                {
                  name: 'shippingOverview',
                  path: 'shippingOverview/index',
                  meta: { title: 'TradeDetails' },
                  component: () => import('../views/company/Trade/ShippingOverview/index.vue'),
              },
              {
                name: 'countries',
                path: 'countries/index',
                meta: { title: 'TradeDetails' },
                component: () => import('../views/company/Trade/Countries/index.vue'),
            },
            {
              name: 'partners',
              path: 'partners/index',
              meta: { title: 'TradeDetails' },
              component: () => import('../views/company/Trade/Partners/index.vue'),
          },
          {
            name: 'products',
            path: 'products/index',
            meta: { title: 'TradeDetails' },
            component: () => import('../views/company/Trade/products/index.vue'),
        },
        {
          name: 'tradeTrends',
          path: 'tradeTrends/index',
          meta: { title: 'TradeDetails' },
          component: () => import('../views/company/Trade/TradeTrends/index.vue')
      },

                    // creditOpinion 模块
                    
                    {
                        name: 'creditOpinion',
                        path: 'creditOpinion/list',
                        meta: { title: 'TradeDetails' },
                        component: () => import('../views/company/Trade/CreditOpinion/list.vue'),
                    },
                    // Market Analysis模块
           
                ]
            },
                 // 贸易产品详情
            {
                  path: 'TradeProduct/TradeDetail/:id',
                  component: () => import('../views/company/TradeProductDetail.vue'),
                  name: 'TradeProductDetails',
                  meta: { title: 'TradeProductDetails' },
                  children: [
                      // background部分
                      {
                          name: 'TradeProductDetailstradeMap',
                          path: 'tradeMap/map',
                          meta: { title: 'TradeProductDetails' },
                          component: () => import('../views/company/TradeProduct/tradeMap/map.vue'),
                      },
                      {
                        name: 'TradeProductDetailssupplyChain',
                        path: 'supplyChain/index',
                        meta: { title: 'TradeProductDetails' },
                        component: () => import('../views/company/TradeProduct/SupplyChain/index.vue'),
                    },
                    {
                      name: 'TradeProductDetailscompetitiveness',
                      path: 'competitiveness/index',
                      meta: { title: 'TradeProductDetails' },
                      component: () => import('../views/company/TradeProduct/Competitiveness/index.vue'),
                  },
                  {
                    name: 'TradeProductDetailsshippingOverview',
                    path: 'shippingOverview/index',
                    meta: { title: 'TradeProductDetails' },
                    component: () => import('../views/company/TradeProduct/ShippingOverview/index.vue'),
                },
                {
                  name: 'TradeProductDetailsorigin&Destination',
                  path: 'origin&Destination/index',
                  meta: { title: 'TradeProductDetails' },
                  component: () => import('../views/company/TradeProduct/origin&Destination/index.vue'),
              },
              {
                name: 'TradeProductDetailssuppliers&Buyers',
                path: 'suppliers&Buyers/index',
                meta: { title: 'TradeProductDetails' },
                component: () => import('../views/company/TradeProduct/suppliers&Buyers/index.vue'),
            },
            {
              name: 'TradeProductDetailsproducts',
              path: 'products/index',
              meta: { title: 'TradeProductDetails' },
              component: () => import('../views/company/TradeProduct/products/index.vue'),
          },
          {
            name: 'TradeProductDetailstradeTrends',
            path: 'tradeTrends/index',
            meta: { title: 'TradeProductDetails' },
            component: () => import('../views/company/TradeProduct/TradeTrends/index.vue')
        },

                      // creditOpinion 模块
                      
                      {
                          name: 'TradeProductDetailssanctionsSituation',
                          path: 'sanctionsSituation/list',
                          meta: { title: 'TradeProductDetails' },
                          component: () => import('../views/company/TradeProduct/sanctionsSituation/list.vue'),
                      },
                      // Market Analysis模块
             
                  ]
            },
            // 贸易国家详情
            {
              path: 'TradeCountry/TradeDetail/:id',
              component: () => import('../views/company/TradeCountryDetail.vue'),
              name: 'TradeCountryDetails',
              meta: { title: 'TradeCountryDetails' },
              children: [
                  // background部分
                  {
                      name: 'TradeCountryDetailstradeMap',
                      path: 'tradeMap/map',
                      meta: { title: 'TradeCountryDetails' },
                      component: () => import('../views/company/TradeCountry/tradeMap/map.vue'),
                  },
                  {
                    name: 'TradeCountryDetailscountryData',
                    path: 'countryData/index',
                    meta: { title: 'TradeCountryDetails' },
                    component: () => import('../views/company/TradeCountry/countryData/index.vue'),
                },
                {
                  name: 'TradeCountryDetailscountries',
                  path: 'countries/index',
                  meta: { title: 'TradeCountryDetails' },
                  component: () => import('../views/company/TradeCountry/Countries/index.vue'),
              },
              {
                name: 'TradeCountryDetailsenterprises',
                path: 'enterprises/index',
                meta: { title: 'TradeCountryDetails' },
                component: () => import('../views/company/TradeCountry/enterprises/index.vue'),
            },
      
   
        {
          name: 'TradeCountryDetailsproducts',
          path: 'products/index',
          meta: { title: 'TradeCountryDetails' },
          component: () => import('../views/company/TradeCountry/products/index.vue'),
      },

      {
        name: 'TradeCountryDetailstradeTrends',
        path: 'tradeTrends/index',
        meta: { title: 'TradeCountryDetails' },
        component: () => import('../views/company/TradeCountry/TradeTrends/index.vue')
    },

                  // creditOpinion 模块
                  
                  {
                      name: 'TradeCountryDetailscreditOpinion',
                      path: 'creditOpinion/list',
                      meta: { title: 'TradeCountryDetails' },
                      component: () => import('../views/company/TradeCountry/creditOpinion/list.vue'),
                  },
                  // Market Analysis模块
         
              ]
        },
            // 公司详情
            {
                path: 'company/detals/:id',
                component: () => import('../views/company/details.vue'),
                name: 'companyDetails',
                meta: { title: '详情页面' },
                children: [
                    // background部分
                    {
                        name: 'backgroundsnapshot',
                        path: 'background/snapshot',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Background/Snapshot.vue'),
                    },
                    {
                        name: 'backgroundprofile',
                        path: 'background/profile',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Background/Profile.vue'),
                    },
                    {
                        name: 'backgroundownership',
                        path: 'background/ownership',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Background/Ownership.vue'),
                    },
                    {
                        name: 'backgroundrelatedCompanies',
                        path: 'background/relatedCompanies',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Background/RelatedCompanies.vue'),
                    },
                    {
                        name: 'backgroundmanagement',
                        path: 'background/management',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Background/Management.vue'),
                    },
                    

                    // businessActivities部分
                    {
                        name: 'businessActivitiesoperations',
                        path: 'business/operations',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/business.vue'),
                    },
                    {
                        name: 'businessActivitieslocations',
                        path: 'business/locations',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/BusinessActivities/Locations.vue'),
                    },
                    {
                        name: 'businessActivitiesindustry',
                        path: 'business/industry',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/BusinessActivities/Industry.vue'),
                    },
                    {
                        name: 'businessActivitiesimport&Export',
                        path: 'business/import&Export',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/BusinessActivities/Import&Export.vue'),
                    },
                    {
                        name: 'businessActivitiesimport&Export',
                        path: 'business/ImportDetail',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/BusinessActivities/ImportDetail.vue'),
                    },
                    {
                        name: 'businessActivitiessupplyChain',
                        path: 'business/supplyChain',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/BusinessActivities/SupplyChain.vue'),
                    },
      
                    // Performance 部分
                    {
                        name: 'performancefinancialHighlights',
                        path: 'performance/financialHighlights',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Performance/FinancialHighlights.vue'),
                    },
                    {
                        name: 'performanceratioAnalysis',
                        path: 'performance/ratioAnalysis',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Performance/RatioAnalysis.vue'),
                    },
                    {
                        name: 'performanceindustryNorms',
                        path: 'performance/industryNorms',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Performance/IndustryNorms.vue'),
                    },

                    // Publications 部分
                    {
                        name: 'publicationslawsuits',
                        path: 'publications/lawsuits',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Publications/Lawsuits.vue'),
                    },
                    {
                        name: 'publicationsnews',
                        path: 'publications/news',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Publications/News.vue'),
                    },
                    {
                        name: 'publicationssanctions',
                        path: 'publications/sanctions',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/Publications/Sanctions.vue'),
                    },
                    // creditOpinion 模块
                    
                    {
                        name: 'creditOpinionlist',
                        path: 'creditOpinion/list',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/CreditOpinion/list.vue'),
                    },
                    // Market Analysis模块
                    {
                        name: 'marketAnalysisindustryStatistics',
                        path: 'marketAnalysis/industryStatistics',
                        meta: { title: 'companyDetails' },
                        component: () => import('../views/company/details/MarketAnalysis/topecharts.vue'),
                    },
                    {
                      name: 'marketAnalysisratingAnalysis',
                      path: 'marketAnalysis/ratingAnalysis',
                      meta: { title: 'companyDetails' },
                      component: () => import('../views/company/details/MarketAnalysis/Comparison.vue'),
                  },
                  {
                    name: 'marketAnalysiscompetitiveAnalysis',
                    path: 'marketAnalysis/competitiveAnalysis',
                    meta: { title: 'companyDetails' },
                    component: () => import('../views/company/details/MarketAnalysis/list.vue'),
                },
                ]
            },
        //  充值模块
            {
              path: 'addCredit',
              name: 'AddCredit',
              component: () =>
                import('../views/addCreat/indextwo.vue'),
            },
            // 个人中心
            {
              path: '/account',
              name: 'Account',
              component: () =>
                import('../views/myAccount/index.vue'),
              children: [
                {
                  path: 'YourUsage',
                  name: 'YourUsage',
                 
                  meta: {
                    father:'Database',
                },
                  component: () =>
                    import('../views/myAccount/Database/yourUsage.vue'),
                },
                {
                  path: 'CompaniesViewed',
                  name: 'CompaniesViewed',
                  meta: {
                    father:'Database',
                },
                  component: () =>
                    import('../views/myAccount/Database/CompaniesViewed.vue'),
                },
                {
                  path: 'ReprotDownloaded',
                  name: 'ReprotsDownloaded',
                  meta: {
                    father:'Database',
                },
                  component: () =>
                    import('../views/myAccount/Database/ReprotsDownloaded.vue'),
                },
                {
                  path: 'UpdatesOrdered',
                  name: 'UpdatesOrdered',
                  father:'Database',
                  meta: {
                    father:'Database',
                },
                  component: () =>
                    import('../views/myAccount/Database/UpdatesOrdered.vue'),
                },
          // Billing and Payment
          {
              path: 'BillRecord',
              name: 'BillRecord',
              meta: {
                father:'Billing',
            },
              component: () =>
                import('../views/myAccount/Biling/BillRecord.vue'),
            },
            {
              path: 'DeductionRecord',
              name: 'DeductionRecord',
              meta: {
                father:'Billing',
            },
              component: () =>
                import('../views/myAccount/Biling/DeductionRecord.vue'),
            },
            {
              path: 'InvoiceDownload',
              name: 'InvoiceDownload',
              meta: {
                father:'Billing',
            },
              component: () =>
                import('../views/myAccount/Biling/InvoiceDownload.vue'),
            },
            {
              path: 'PaymentRecord',
              name: 'PaymentRecord',
              meta: {
                father:'Billing',
            },
              component: () =>
                import('../views/myAccount/Biling/PaymentRecord.vue'),
            },
          //   User Settings
          {
              path: 'UserSettings',
              name: 'UserSettings',
              meta: {
                father:'UserSettings',
            },
              component: () =>
                import('../views/myAccount/UserSetting.vue'),
            },
          //Notification Message
          {
              path: 'Notifications',
              name: 'Notifications',
              meta: {
                father:'Notifications',
            },
              component: () =>
                import('../views/myAccount/NotificationMessage/Notifications.vue'),
            },
            {
              path: 'Newsforyou',
              name: 'Newsforyou',
              meta: {
                father:'Notifications',
            },
              component: () =>
                  import('../views/myAccount/NotificationMessage/Newsforyou.vue'),
            },
            //Help Center
            {
              path: 'FAQ',
              name: 'FAQ',
              meta: {
                father:'Help',
            },
              component: () =>
                import('../views/myAccount/HelpCenter/faq.vue'),
            },
            {
              path: 'PrivacyPolicy',
              name: 'PrivacyPolicy',
              meta: {
                father:'Help',
            },
              component: () =>
                import('../views/myAccount/HelpCenter/PrivacyPolicy.vue'),
            },
            {
              path: 'Terms',
              name: 'Terms',
              meta: {
                father:'Help',
            },
              component: () =>
                import('../views/myAccount/HelpCenter/Terms.vue'),
            },
            {
              path: 'Instructions',
              name: 'Instructions',
              meta: {
                father:'Help',
            },
              component: () =>
                import('../views/myAccount/HelpCenter/Instructions.vue'),
            },
            {
              path: 'ReportaProblem',
              name: 'ReportaProblem',
              meta: {
                father:'Help',
            },
              component: () =>
                import('../views/myAccount/HelpCenter/ReportaProblem.vue'),
            },
            {
              path: 'About',
              name: 'About',
              meta: {
                father:'About',
            },
              component: () =>
                import('../views/myAccount/About.vue'),
            },
          //   Organizational Manag
          {
              path: 'Roles',
              name: 'Roles',
              meta: {
                father:'Organizational',
            },
              component: () =>
                import('../views/myAccount/OrganizationalManagement/Roles.vue'),
            },
            {
              path: 'User',
              name: 'User',
              meta: {
                father:'Organizational',
            },
              component: () =>
                import('../views/myAccount/OrganizationalManagement/User.vue'),
            },
            {
              path: 'UserGroup',
              name: 'UserGroup',
              meta: {
                father:'Organizational',
            },
              component: () =>
                import('../views/myAccount/OrganizationalManagement/UserGroup.vue'),
            },
          //   Account
          {
              path: 'MyAccount',
              name: 'MyAccount',
              meta: {
                father:'Account',
            },
              component: () =>
                import('../views/myAccount/Account/MyAccount.vue'),
            },
            {
              path: 'ContactInformation',
              name: 'ContactInformation',
              meta: {
                father:'Account',
            },
              component: () =>
                import('../views/myAccount/Account/ContactInformation.vue'),
            },
            {
              path: 'AccountSecurity',
              name: 'AccountSecurity',
              meta: {
                father:'Account',
            },
              component: () =>
                import('../views/myAccount/Account/AccountSecurity.vue'),
            },
              ]
            },
          
            // APP外链

            
        ]
    },
  
    // 购买
   
      //个人中心


]

export default routes;
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
