import { getPerformance } from "../../api/companyApi";
import crypto from '@/utils/crypto'
import {
  Message
} from 'element-ui'
const state = {
  cardlist: [
  ],
}
const getters = {
  cardlist1(state) {
    return state.cardlist
  }
}
const mutations = {
  setcardlist(state, cardlist) {
    state.cardlist = cardlist
  }
}
const actions = {
  getcardlist({ commit }, params) {
    return new Promise((resolve, reject) => {

      getPerformance(params).then(res => {
        let data = JSON.parse(crypto.decrypt(res))

        if (data.code === true) {
          let list = [
          
        ]

          list.map(item => {

            if (item.name == 'Revenue') {
              item.text = data.data.revenue
            }
            if (item.name == 'Profit') {
              item.text = data.data.profit
            }
            if (item.name == 'Growth') {
              item.text = data.data.growth
            }
            if (item.name == 'Financial Structure') {
              item.text = data.data.structure
            }
            if (item.name == 'Credit Opinion') {
              item.text = data.data.creditOpinion
            }
            if (item.name == 'Liquidity') {
              item.text = data.data.liquidity
            }
            if (item.name == 'Risk Indicator') {
              item.text = data.data.riskIndicator
            }
            if (item.name == 'Solvency index (SI)') {
              item.text = data.data.si
            }

          })
          commit('setcardlist', list)



          // resolve(data.data)
        } else {
          commit('setcardlist', [])
          // Message({
          //   message: '账户或密码错误！',
          //   type: 'warning'
          // })
          // return Promise.reject('error')
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}