<template>
    <!--srccomponentsgui_tabsgui_tabsvuehtmlStart-->

    <div id="defaultId1" class="  ueqCkmp" name="header">
        <div id="gf9e12" class="   u687b7">
            <slot id="c605d2">
            </slot>
        </div>
    </div>
    <!--srccomponentsgui_tabsgui_tabsvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",
        props: {
            // srccomponentsgui_tabsgui_tabsvuePropsStart

            // srccomponentsgui_tabsgui_tabsvuePropsEnd


        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_tabsgui_tabsvueMountedStart

            // srccomponentsgui_tabsgui_tabsvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srccomponentsgui_tabsgui_tabsvueDataStart

                // isMenu
                isMenu: true
                // srccomponentsgui_tabsgui_tabsvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_tabsgui_tabsvueMethodStart

            // srccomponentsgui_tabsgui_tabsvueMethodEnd

        },

        computed: {
            tabs() {
                return this.$slots.default.map(item => item.data).filter(item => item);
            }
        }
    };
</script>
<style>
    .srccomponentsgui_tabsgui_tabsvuecssStart {}

    .ueqCkmp {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .u687b7 {
        display: flex;
        /* padding-right: 0.5em;
        padding-top: 0.2em;
        padding-left: 0.5em;
        padding-bottom: 0.2em; */
        padding-right: 2.1428rem;
        flex: auto;
        border-bottom-left-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        align-items: center;
    }

    .srccomponentsgui_tabsgui_tabsvuecssEnd {}
    @media (min-width: 960px) and (max-width: 1400px) {
    /* 在此处添加样式 */


 .uJeKUqr{
    padding-left: 0px !important;
 }
 .usRlj2Q,.uGuqXzB,.ueqCkmp{
margin-left:1.07142rem !important;
 }
   .u52ab1{
    margin-right: 2em;
   }    


}
@media (min-width: 1440px) and (max-width: 1501px) {
    /* 在此处添加样式 */



 .usRlj2Q,.uGuqXzB,.ueqCkmp{
margin-left:1.57142rem !important;
 }
   .u52ab1{
    margin-right: 2em;
   }    


}
</style>