import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import User from './modules/User'
import performance from './modules/performance'
import app from './modules/app'
import websocket from './modules/websocket';

Vue.use(Vuex)

const state = {
  httpimgurl: '',
  //国家集合，
  countryImgList: [],
  rightList:[],//公司详情菜单
  // 刷新key
  refreshKey: '',
  productOptions:[],
}
const mutations= {
  // 设置刷新
  setRefreshKey(state, key) {
    state.refreshKey = key;
  }
}
const getters= {
  refreshKey: state => state.refreshKey,
}
export default new Vuex.Store({
  // 仓库存放数据
  state,
  mutations,
  getters,
  // 模块
  modules: {
    users,
    app,
    performance,
    User,
    websocket
  }
})
