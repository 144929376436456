<template>
    <!--srccomponentsgui_scroll_xgui_scroll_xvuehtmlStart-->

    <div id="defaultId1">
        <div id="idk1z9qS" class="  scroll-container" ref="container">
            
            <div id="id8RMA9z" class="  scroll-content" ref="content">
                <div id="idwcmjvI" class="  scroll-content-box">
                    <slot id="idbHp7NR">
                    </slot>
                </div>
            </div>
            <button id="idHWVxE3" @click="scrollLeft" class=" prev  scroll-btn" :disabled="scrollLeftDisabled">
                <svg id="idRA4USO" class="icon " t="1717125980355" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5638" width="1.142rem" height="1.142rem">
                    <path id="id4Rg6UR" d="M705 923c-6.4 0-12.8-2.4-17.7-7.3l-386-386c-9.8-9.8-9.8-25.6 0-35.4l386-386c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L354.4 512l368.3 368.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.9-11.3 7.3-17.7 7.3z" :fill="$store.state.app.themeColor" p-id="5639">
                    </path>
                </svg>
            </button>
            <button id="idgqE5ZX" @click="scrollRight" class="scroll-btn   next" :disabled="scrollRightDisabled">
                <svg id="idtA1Aih" class="icon " t="1717126183018" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5799" width="1.242rem" height="1.242rem">
                    <path id="idGbhbgH" d="M319 923c-6.4 0-12.8-2.4-17.7-7.3-9.8-9.8-9.8-25.6 0-35.4L669.6 512 301.3 143.7c-9.8-9.8-9.8-25.6 0-35.4 9.8-9.8 25.6-9.8 35.4 0l386 386c9.8 9.8 9.8 25.6 0 35.4l-386 386c-4.9 4.9-11.3 7.3-17.7 7.3z" :fill="$store.state.app.themeColor" p-id="5800">
                    </path>
                </svg>
            </button>
        </div>
    </div>
    <!--srccomponentsgui_scroll_xgui_scroll_xvuehtmlEnd-->

</template>

<script>
    function waitForScrollEnd(callback, delay) {
        let timeout;
        window.addEventListener('scroll', function onScroll() {
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                window.removeEventListener('scroll', onScroll);
                callback();
            }, delay);
        });
    }
    // interfaceCode
    // :style="{ width: contentWidth + 'px' }"

    // interfaceCode
    //importStart

    //importEnd


    // interfaceCode
    export default {
        name: 'HorizontalScroll',
        data() {
            return {
                // insertData
                // srccomponentsgui_scroll_xgui_scroll_xvueDataStart

                // contentWidth
                contentWidth: 0,
                // scrollLeftDisabled
                scrollLeftDisabled: true,
                // 
                checkScrollTime: '',
                // scrollRightDisabled
                scrollRightDisabled: true
                // srccomponentsgui_scroll_xgui_scroll_xvueDataEnd

            };
        },
        methods: {
            // insertMethod
            // srccomponentsgui_scroll_xgui_scroll_xvueMethodStart
            // checkScroll
            checkScroll() {


                const container = this.$refs.content;
                const content = this.$refs.content.children[0];

                this.scrollLeftDisabled = container.scrollLeft === 0;
                this.scrollRightDisabled = container.scrollLeft + container.offsetWidth >= content.scrollWidth;


            }, // scrollLeft
            scrollLeft() {
                const container = this.$refs.content;
                container.scrollBy({
                    left: -100,
                    behavior: 'smooth'
                });
                // 等待滚动结束后执行操作

                if (this.checkScrollTime) {
                    clearTimeout(this.checkScrollTime)
                    this.checkScrollTime = null
                }
                this.checkScrollTime = setTimeout(() => {
                    this.checkScroll();
                }, 800)


            }, // scrollRight
            async scrollRight() {


                const container = this.$refs.content;
                container.scrollBy({
                    left: 100,
                    behavior: 'smooth'
                });
                if (this.checkScrollTime) {
                    clearTimeout(this.checkScrollTime)
                    this.checkScrollTime = null
                }
                this.checkScrollTime = setTimeout(() => {
                    this.checkScroll();
                }, 800)





            }
            // srccomponentsgui_scroll_xgui_scroll_xvueMethodEnd

        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_scroll_xgui_scroll_xvueMountedStart

            // defaultLoad	
            const content = this.$refs.content;
            this.contentWidth = content.scrollWidth;
            this.checkScroll();

            // srccomponentsgui_scroll_xgui_scroll_xvueMountedEnd

        },
        updated() {
            this.checkScroll();
        },

        props: {
            // srccomponentsgui_scroll_xgui_scroll_xvuePropsStart

            // srccomponentsgui_scroll_xgui_scroll_xvuePropsEnd


        },
        components: {}
    };
</script>

<style>
    .srccomponentsgui_scroll_xgui_scroll_xvuecssStart {}

    .scroll-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        overflow-x: hidden;
    }

    .scroll-content {
        width: calc(100% - 80px);
        /* display:flex;
flex-wrap:nowrap;
*/
        overflow-x: auto;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
        margin-left: 40px;
    }

    .scroll-content-box {
        width: max-content;
        display: flex;
        scrollbar-width: none;
    }

    .scroll-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.857rem;
        height: 1.857rem;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: #FFFFFF;
        color: #1290C9;
        border: none;
        outline: none;
        cursor: pointer;
        transition: opacity 0.3s;
        border-bottom-left-radius: 100em;
        border-top-left-radius: 100em;
        border-top-right-radius: 100em;
        border-bottom-right-radius: 100em;
        margin-top: 0.35em;
    }

    .next {
        right: 0;
    }

    .scroll-content::-webkit-scrollbar {
        display: none;
    }

    .scroll-btn:hover {
        opacity: 0.7;
    }

    .scroll-btn:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        fill: #FFF;
    }

    .srccomponentsgui_scroll_xgui_scroll_xvuecssEnd {}





































    /* 禁用状态样式 */
</style>