<template>
    <!--srccomponentsgui_chartgui_chartvuehtmlStart-->

    <div id="defaultId1" class="  u34e96">
        <div id="cdc31b" class="       u01533">
            <div id="cd72ff" class="       u7eb26">
                <div id="g44c09" @click="isVip = !isVip" class="  u64b72">
                    <img id="g61a71" class="u81734" src="../../assets/images/chart2.png" alt="" />
                </div>
                <div id="c8feef" class="       uee8c0">Hi I'm Zaba – Your research assistant

                </div>

                <div id="gbbfc3" @click="hide" class="  uad917">

                    <i class="el-icon-close iconDelete"></i>
                    <!-- <svg t="1734493789277"  class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6041" width="2em" height="2em"><path d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z" fill="#FFFFFF" p-id="6042"></path></svg> -->
                    <svg id="c1e481" @click="isShowChat = false" class="icon      uVMhwhN" t="1709707145644"
                        viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6200" width="16"
                        height="16">
                        <path id="c171b2" d="M0 0h1024v1024H0z" fill="#FF0033" fill-opacity="0" p-id="6201">
                        </path>
                        <path id="c59831"
                            d="M240.448 168l2.346667 2.154667 289.92 289.941333 279.253333-279.253333a42.666667 42.666667 0 0 1 62.506667 58.026666l-2.133334 2.346667-279.296 279.210667 279.274667 279.253333a42.666667 42.666667 0 0 1-58.005333 62.528l-2.346667-2.176-279.253333-279.253333-289.92 289.962666a42.666667 42.666667 0 0 1-62.506667-58.005333l2.154667-2.346667 289.941333-289.962666-289.92-289.92a42.666667 42.666667 0 0 1 57.984-62.506667z"
                            fill="#111111" p-id="6202">
                        </path>
                    </svg>
                </div>
            </div>
            <div id="c8e57a" class="            u98bef">
                <div id="c630d0" class="            u213f4" ref="messages">

                    <div id="cdec0b" v-for="(item, index) in chartList" :key="index">
                        <div id="cc2536" class="  ue7063" v-if="!item.isMy">
                            <div id="c6711b" class="u1f654">

                                <img id="ca532a" v-if="loadig && index == chartList.length - 1" class="           u5c5dc"
                                    src="../../assets/images/Aigif.gif" alt="" />
                                <img id="ca532a" v-else class="           u5c5dc" src="../../assets/images/chart2.png"
                                    alt="" />
                            </div>
                            <div id="c23250" class="           uda7fd">

                                <div id="c5f6f8" class="           ub575f" v-html="$marked(item.content)">
                                </div>


                            </div>

                        </div>
                        <div id="c24975" class="    ue7063         u042ca" v-if="item.isMy">
                            <div id="c58973" class="    u1f654   
                                  ">
                                <img id="cdff08" :src="imageUrl" v-if="imageUrl" class="u5c5dc" />
                                <div v-else class="avatar avatartex avatarnologin">

                                    <div class="avatar_content">
                                        {{ userInfo?.userInfo?.firstName }}
                                    </div>

                                </div>
                            </div>
                            <div id="c37c2c" class="    uda7fd         uffe54">
                                <div id="c2bd98" class="    ub575f         uCTCvwp" v-html="item.content">
                                </div>
                                <!-- 箭头
                                 <div id="cd1652" class="    u850d0         uc4835" >
                                 </div> -->
                            </div>
                        </div>
                    </div>
                    <div id="c9674d" v-loading="loadig" class="           u55a82">
                    </div>
                    <!-- <div id="c5f6f8"  class="           ub575f"   v-html="eventStreamData">  -->
                </div>

            </div>
        </div>
        <!-- 分割线
               <div id="g6880e" class="  uc0fd1" >
               </div> -->
        <div id="cf58eb" class="            uddc76">
            <div class="messAgeClose" v-if="chartList.length > 0 && !loadig">
                <span class="messAgeClose-text" @click="chartList = []">
                    <svg t="1742890623465" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="6444" width="20" height="20">
                        <path
                            d="M820.87 100.46c73.4-0.01 134.16 57.03 138.79 130.29l0.26 8.76V414.6c0.1 19.03-14.61 34.86-33.6 36.15-18.99 1.3-35.7-12.39-38.19-31.26l-0.31-4.89V239.51c0-34.32-25.96-63.08-60.1-66.59l-6.85-0.36H202.91c-34.32 0-63.08 25.96-66.59 60.1l-0.36 6.85v411.98c0 34.31 25.96 63.07 60.1 66.59l6.85 0.36h41.19c18.03-0.01 33.29 13.29 35.74 31.16l0.31 4.89v82.39l148.31-111.23a36.067 36.067 0 0 1 15.86-6.69l5.77-0.52h144.19c19.03-0.1 34.86 14.61 36.16 33.6 1.29 18.99-12.4 35.71-31.27 38.19l-4.89 0.31H462.04L306.93 906.92c-25.22 18.91-60.64 15.81-82.19-7.21l-4.33-5.15a61.813 61.813 0 0 1-11.85-29.09l-0.52-7.98-0.05-66.95h-5.1c-73.4 0.01-134.17-57.03-138.79-130.29l-0.26-8.75V239.51c-0.01-73.4 57.03-134.17 130.29-138.79l8.76-0.26h617.97z m-20.6 392c18.05-0.01 33.32 13.32 35.74 31.21l0.3 4.89v85.59h87.55c19.03-0.1 34.86 14.61 36.15 33.6 1.3 18.99-12.39 35.71-31.26 38.19l-4.89 0.31h-87.55v85.59c0.1 19.03-14.61 34.86-33.59 36.15-18.99 1.3-35.71-12.39-38.19-31.26l-0.31-4.89v-85.59h-87.54c-19.03 0.1-34.86-14.61-36.16-33.6-1.29-18.99 12.4-35.71 31.26-38.19l4.9-0.31h87.54v-85.59a36.052 36.052 0 0 1 36.05-36.05v-0.05z m-261.6-52.12c19.04-0.13 34.89 14.58 36.21 33.57 1.31 18.99-12.39 35.73-31.26 38.21l-4.89 0.31H291.55c-19.03 0.1-34.86-14.61-36.16-33.6-1.29-18.99 12.4-35.7 31.26-38.19l4.84-0.31h247.19zM415.08 297.23c19.04-0.13 34.89 14.58 36.21 33.57 1.31 19-12.39 35.73-31.27 38.21l-4.89 0.31H291.54c-18.88-0.1-34.49-14.76-35.77-33.59-1.29-18.84 12.18-35.48 30.88-38.14l4.9-0.36h123.59-0.05z m0 0"
                            p-id="6445" fill="#1296db"></path>
                    </svg>
                    Open a new conversation
                </span>
            </div>
            <div class="uddc76Content">

                <div id="gbc645" class="  u7c8c3">
                    <div id="g20209" class="  ub8303">
                        <!-- 注释标记
                                 <textarea id="c0fa8f" v-model="str" class="            ub5c82" placeholder="请输入内容" >
                                 </textarea> -->
                        <el-input id="c5d59f" v-loading="loadig" @keydown.native="handleKeydown($event)" v-model="str"
                            type="textarea" :autosize="{ 'minRows': 4, 'maxRows': 4 }"
                            placeholder="What are you searching for? " />
                    </div>

                    <div id="gb879d" class="  u49be8">
                        <button id="c09127" @click="sendMsg()" :class="str ? 'ucLkAbZ ucLkAbZbluc' : 'ucLkAbZ'"
                            type="primary" :disabled="!str">
                            <svg t="1742882606489" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="6194" width="20" height="20">
                                <path d="M0 0h1024v1024H0z" fill="#ffffff" opacity=".01" p-id="6195"></path>
                                <path
                                    d="M487.867733 146.5344a34.133333 34.133333 0 0 1 48.264534 0l307.2 307.2a34.133333 34.133333 0 0 1-48.264534 48.264533L546.133333 253.064533V853.333333a34.133333 34.133333 0 1 1-68.266666 0V253.064533l-248.9344 248.9344a34.133333 34.133333 0 0 1-48.264534-48.264533l307.2-307.2z"
                                    fill="#ffffff" p-id="6196"></path>
                            </svg>
                        </button>
                    </div>
                </div>

            </div>
            <div v-if="CreditAnalyst">
                <div class="uddc76ContentTwo">
                    <div class="uddc76ContentTwo-box"
                        :class="tagsArrListIndex == index ? 'uddc76ContentTwo-box-bortder' : 'uddc76ContentTwo-box'"
                        v-for="(item, index) in tagsArrList" :key="index" @click="tagsArrHover(item, index)">
                        <p>{{ item.head }}</p>
                        <div class="uddc76ContentTwo-boxtext">
                            {{ item.text }}
                        </div>

                    </div>




                </div>
            </div>
            <div v-if="!CreditAnalyst">
                <div class="uddc76ContentTwo">
                    <div class="uddc76ContentTwo-box uddc76ContentTree-box"
                        :class="tagsArrListIndex == index ? 'uddc76ContentTwo-box-bortder' : 'uddc76ContentTwo-box uddc76ContentTree-box'"
                        v-for="(item, index) in tagsArrList" :key="index" @click="tagsArrHover(item, index)">
                        <span>{{ item.head }}</span>
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="gad2b2" v-show="isShowTool">
                <div id="cb8ef0" class="  uc0fd1  ">
                </div>
                <div id="g01ad5" class="  u60034">
                    <div id="gd8ecb" class="  ud136d">
                        <div id="g6d328" @click="isShowTool = false" v-for="item in 4" class="  uf716f">
                            <div id="gf9592" class="  u7c0ad">
                                <img id="g9826d" class="  u635e3" src="../../assets/images/1718087315347.jpg" alt="" />
                            </div>
                            <div id="g56bac" class="  u47c8b">相册
                            </div>
                        </div>
                    </div>
                    <div id="ce1b08" class="  ud136d  ">
                        <div id="cdc330" @click="isShowTool = false" v-for="item in 4" class="  uf716f  " key="index">
                            <div id="c17c0f" class="  u7c0ad  ">
                                <img id="ce9ed5" class="  u635e3  " src="../../assets/images/1718087315347.jpg"
                                    alt="" />
                            </div>
                            <div id="c628a8" class="  u47c8b  ">相册
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
    <!--srccomponentsgui_chartgui_chartvuehtmlEnd-->

</template>
<script>
import {

    getAiQuestion
} from "@/api/companyApi";
import gui_scroll_x from '../gui_scroll_x/gui_scroll_x.vue'
//importEnd
export default {
    name: '',
    props: {
        // srccomponentsgui_chartgui_chartvuePropsStart

        // 高度
        height: {
            type: Number,
            default: 992
        },
        // 宽度
        width: {
            type: Number,
            default: 620
        }
        // srccomponentsgui_chartgui_chartvuePropsEnd


    },
    mounted() {
        // defaultLoad
        // srccomponentsgui_chartgui_chartvueMountedStart

        // srccomponentsgui_chartgui_chartvueMountedEnd


    },
    components: {
        // gui_scroll_x start
        gui_scroll_x,
        // gui_scroll_x end


    },
    data() {
        return {
            // insertData
            // srccomponentsgui_chartgui_chartvueDataStart

            // 是否vip
            isVip: false,
            loadig: false,
            CreditAnalyst: true,
            // chartList
            chartList: [],
            // tags
            tags: [{
                "name": "Credit Analyst"
            }, {
                "name": "Legal Advisor"
            }, {
                "name": "Biz Consultant "
            }, {
                "name": "Economic"
            },],
            tagsArrList: [
                {
                    text: 'Assesses a company’s creditworthiness, identifiesfinancial risks, ensuring secure transactions through data analysis and credit ratings.',
                    head: 'Credit Analyst'
                },
                {
                    text: 'Provides compliance guidance, reviews contracts,andassesses legal risks to ensure business operations align with laws and avoid disputes.',
                    head: 'Legal Advisor'
                },
                {
                    text: 'Optimizes supply chains,identifiesbuyers, and boosts sales by analyzing market trends and offering tailored strategies to improve efficiency.',
                    head: 'Biz Consultant'
                },
                {
                    text: 'Analyzes industry and regional economic trends, helping businesses make informed decisions, and stay competitive.',
                    head: 'Economic Analyst'
                }
            ],
            // 
            fileName: '',
            tagsArrListIndex: 5,
            fileNameObj: {
                file_name: 'one'
            },
            userInfo: JSON.parse(localStorage.getItem("userInfo")),
            audioUploadPercent: '',
            audioFlag: '',
            str: '',
            eventSource: null,
            token: localStorage.getItem("token"),
            isShowTool: false,
            eventStreamData: '',
            imageUrl:
                JSON.parse(localStorage.getItem("userInfo"))?.userInfo?.userImg == null
                    ? ""
                    : 'https://www.csibizsearch.com/csi/usercenter/download/resource?resource=' + JSON.parse(localStorage.getItem("userInfo"))?.userInfo.userImg,
            // srccomponentsgui_chartgui_chartvueDataEnd

        }
    },
    created() {

        if (this.token) {
            this.imageUrl = JSON.parse(localStorage.getItem('userInfoImg')) == undefined ? this.imageUrl : JSON.parse(localStorage.getItem('userInfoImg'))

        }
    },

    methods: {
        tagsArrHover(item, index) {

            if (index != this.tagsArrListIndex) {
                // this.str = item.head
                this.tagsArrListIndex = index
            }else{
                this.tagsArrListIndex=undefined
            }

        },
        // insertMethod
        // srccomponentsgui_chartgui_chartvueMethodStart
        // 点击隐藏
        hide() {
            this.CreditAnalyst = true
            this.$emit('hide', false)
        }, // 
        getFilename(file) {

            this.fileNameObj.file_name = file.name

        }, // 
        uploadaudioProcess(event, file, fileList) {

            this.audioFlag = true
            this.audioUploadPercent = file.percentage.toFixed(0) * 1;

        }, // 
        handleaudioSuccess(res, file, x) {

            this.audioFlag = false;
            this.audioUploadPercent = 0;
            if (res.data.info) { // 首次上传找不到filename
                x.note = res.data.info.url
            }


        }, // 
        beforeAvatarUpload(file) {

            const isJPG = /image.*/.test(file.type);
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('Uploading avatar images can only be in JPG format!');
            }
            if (!isLt2M) {
                this.$message.error('Uploading avatar images can only be in JPG format!');
            }
            this.fileNameObj.file_name = file.name
            return isJPG && isLt2M;

        }, // sendMsg
        handleKeydown(event) {
            if (event.key == "Enter" || event.code == "Enter" || event.keyCode == 13) {

                event.preventDefault();
                this.sendMsg()


                // }

            }

        },

        sendMsg() {
            this.CreditAnalyst = false

            if (!this.str) {
                return;
            }

            this.chartList.push({
                name: "我",
                isMy: true,
                content: this.str
            });

            this.startEventStream()
          

       
            this.chartList.push({
                "name": "baza",
                "isMy": false,
                "content": ''
            });
            this.str = "";

        },
        startEventStream() {
            const token = localStorage.getItem('token')
            this.loadig = true
            // 创建 EventSource 实例，连接到服务器的事件流
            let api = process.env.NODE_ENV == 'development' ? '/devapi' : 'https://www.csibizsearch.com/csi'//new-测试
            this.eventSource = new EventSource(api + '/ai/question?country=CN&question=' + this.str + '&Authorization=' + token + '&chatId=' + token + '&uid=' + this.userInfo?.userInfo?.id + '&name=123');

            // 监听消息事件
            this.eventSource.onmessage = (event) => {

                const data = JSON.parse(event.data);
                if (data.content != null) {
                    const messagesContainer = this.$refs.messages;

                    setTimeout(() => {
                        this.chartList[this.chartList.length - 1].content += data.content
                        this.$nextTick(() => {
                            messagesContainer.scrollTop = messagesContainer.scrollHeight
                        });
                        // this.chartList[this.chartList.length - 1].content += htmlContent;
                    }, 1000);

                }



            };

            // 监听错误事件
            this.eventSource.onerror = (error) => {
                this.loadig = false; // 停止加载状态
                this.eventSource.close()
            };

            // 监听连接打开事件
            this.eventSource.onopen = () => {
                // this.loadig = true
            };
        },
        stopEventStream() {
            if (this.eventSource) {
                this.loading = false; // 停止加载状态
                this.eventSource.close(); // 关闭 EventSource 连接
            }
        },


    },

    computed: {}
}
</script>
<style scoped>
.u34e96 {
    width: 100%;
    height: 100%;
    box-shadow: -0.7142857142857143rem 0.7142857142857143rem 1rem 0rem rgba(0, 0, 0, 0.12);
}

.u01533 {
    display: flex;
    width: 100%;
    height: 67%;
    flex-direction: column;
    position: static;
    overflow-x: hidden;
    overflow-y: hidden;
    right: 0.07142857142857142rem;
    top: 5.428571428571429rem;
}

.u7eb26 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 6.285714285714286rem;
    padding-left: 2.142857142857143rem;
    padding-right: 2.142857142857143rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgba(18, 144, 201, 1);
}

.u64b72 {
    margin-right: 0.7142857142857143rem;
}

.uc7540 {
    width: 7.142857142857143rem;
}

.ub8393 {
    width: 7.142857142857143rem;
}

.uee8c0 {
    flex: auto;
    font-size: 1.4857142857142858rem;
    color: #FFFFFF;
    line-height: 1.5rem;
    text-align: left;
    font-style: normal;
}

.uad917 {
    height: 1.4285714285714286rem;
}

.uVMhwhN {
    display: none;
}

.u98bef {
    display: flex;
    height: 0;
    flex-direction: column;
    flex: auto;
    overflow-y: auto;

}

.u213f4 {
    flex: auto;
    overflow-x: auto;
    padding: 1.42857143rem;
}

.u55a82 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.42857142857142855rem;
    font-size: 1rem;
    color: #A2A9B0;
    line-height: 1.1428571428571428rem;
    text-align: center;
    font-style: normal;
}

.ue7063 {
    display: flex;
    margin-bottom: 1.8571428571428572rem;
}

.u1f654 {
    width: 2.857142857142857rem;
    height: 2.857142857142857rem;
    overflow-x: hidden;
    overflow-y: hidden;
    border-bottom-left-radius: 100em;
    border-top-left-radius: 100em;
    border-top-right-radius: 100em;
    border-bottom-right-radius: 100em;
    margin-right: 0.7142857142857143rem;
    background: #f5f5f5;
}

.u5c5dc {
    width: 100%;
    height: 100%;
}

.uda7fd {
    width: 0%;
    flex: auto;
    box-sizing: content-box;
    position: relative;
}

.zaba_name {
    color: #8497AB;
    margin-bottom: 0.5714285714285714rem;
    margin-top: -0.7142857142857143rem;
    font-size: 1.1428571428571428rem;
    text-align: left;
    line-height: 1.2857142857142858rem;
    font-style: normal;
    ;
}

.ub575f {
    width: max-content;
    color: #1A2332;
    font-size: 1.142857142857143rem;
    padding-left: 1.4285714285714286rem;
    padding-right: 1.4285714285714286rem;
    padding-top: 0.7142857142857143rem;
    padding-bottom: 0.7142857142857143rem;
    border-bottom-left-radius: 1.1428571428571428rem;
    border-top-left-radius: 0rem;
    border-top-right-radius: 1.1428571428571428rem;
    border-bottom-right-radius: 1.1428571428571428rem;
    min-height: 1.4285714285714286rem;
    max-width: 100%;
    word-wrap: break-word;
    ;
}

.u850d0 {
    width: 0;
    height: 0;
    transform: rotate(-90deg);
    position: absolute;
    top: 1.0714285714285714rem;
    left: -1.0714285714285714rem;
    border-left: 0.7142857142857143rem solid transparent;
    border-right: 0.7142857142857143rem solid transparent;
    border-bottom: 1.4285714285714286rem solid #EEEEEE;
    ;
}

.u042ca {
    display: flex;
    flex-direction: row-reverse;
}

.uffe54 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 0.7142857142857143rem;
}

.my_name {
    color: #8497AB;
    margin-bottom: 0.5714285714285714rem;
    margin-top: -0.7142857142857143rem;
    font-size: 1.1428571428571428rem;
    text-align: right;
    line-height: 1.2857142857142858rem;
    font-style: normal;
    ;
}

.uCTCvwp {
    color: rgba(26, 35, 50, 1);
    background-color: #EEEEEE;
    border-bottom-left-radius: 1.1428571428571428rem;
    border-top-left-radius: 1.1428571428571428rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 1.1428571428571428rem;
}

.uc4835 {
    transform: rotate(90deg);
    position: absolute;
    right: -1.1428571428571428rem;
    top: 1.2142857142857142rem;
    left: unset;
    border-bottom-color: #238ACC
}

.uc0fd1 {
    width: 100%;
    height: 0.07142857142857142rem;
    background: #CCCCCC;
    ;
}

.index_chart_gpt {
    position: relative;
}

.uddc76 {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    background: #fff;
}

.u1a8f6 {
    width: 100%;
    position: relative;
    top: -0.7rem;
    /* display: none; */
    /* margin-bottom: 1.4285714285714286rem; */
}

.chart_mark_box {
    flex: none;
}

.mark_box {
    display: flex;
    padding-left: 0.7142857142857143rem;
    padding-right: 0.7142857142857143rem;
    padding-top: 0.42857142857142855rem;
    padding-bottom: 0.42857142857142855rem;
    margin-right: 0.42857142857142855rem;
    border: 0.07142857142857142rem solid #DDDDDD;
    border-radius: 1.7857142857142858rem;
    background: #FFFFFF;
}

.mark_span {
    color: #1A2332;
    font-size: 1.142857142857143rem;
    margin-right: 0.1em;
}

.u2d77a {
    display: flex;
    margin-bottom: 0.5714285714285714rem;
}

.uA4uZnn {
    margin-right: 1.4285714285714286rem;
}

.u7c8c3 {
    /* display: flex;
    align-items: flex-start; */
    position: relative;
}

.ub8303 {
    flex: auto;
    padding-left: 0.714285714rem;
}

.ub5c82 {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #515a6e;
    position: relative;
    margin-right: 0.5em;
    font-size: 1rem;
    line-height: 1.5;
    background-image: none;
    outline: none;
    border: none;
    cursor: text;
    padding: 0.2857142857142857rem 0.5rem;
    border-radius: 0.2857142857142857rem;
    -webkit-transition: border 0.2s ease-in-out background 0.2s ease-in-out -webkit-box-shadow 0.2s ease-in-out;
    transition: border 0.2s ease-in-out background 0.2s ease-in-out box-shadow 0.2s ease-in-out -webkit-box-shadow 0.2s ease-in-out;
}

.ud5f6e {
    color: rgba(8, 8, 8, 1);
    font-size: 1.2857142857142858rem;
    text-align: left;
    line-height: 1.5rem;
    font-style: normal;
    ;
}

.u49be8 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0rem;
    right: -0.7rem;
}

.ucLkAbZ {
    width: 2.857142857142857rem;
    height: 2.857142857142857rem;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 50%;
    background: #D5DEE8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u60034 {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.ud136d {
    display: flex;
    justify-content: space-around;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.uf716f {
    width: 3.7142857142857144rem;
}

.u7c0ad {
    background-color: rgba(98, 171, 249, 1);
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom-left-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}

.u635e3 {
    width: 100%;
}

.u47c8b {
    text-align: center;
}

.mark_box i {
    color: rgb(254, 3, 3);
}

.iconDelete {
    font-size: 1.9em;
    color: #fff;
}

.avatar_content {
    font-size: 1.2857142857142856rem;

}

.avatarnologin {
    width: 2.857142857142857rem;
    height: 2.857142857142857rem;
    border-radius: 50%;
    display: block;
    background: #f5f5f5;

    line-height: 2.857142857142857rem;
    text-align: center;
    color: #a2a9b0;
    overflow: hidden;
}

/deep/ .el-textarea__inner {
    border: none !important;
    /* background-color: #EEEEEE; */
    font-size: 1.42865714rem;
    color: #1A2332;
    padding: 0px;
}

.uddc76Content {
    margin: 1.4285714rem 2.14285714rem;
    margin-bottom: 10px;
    padding: 1.4285714rem;
    padding-left: 0.714285714rem;

    padding-bottom: 0.5rem;
    /* background: #EEEEEE; */
    border: 1px solid #999999;

    border-radius: 16px;
}

.uddc76ContentTwo {
    margin: 0rem 2.14285714rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.4285714rem;
}

.uddc76ContentTwo-box {
    padding: 1.4285714rem;
    border-radius: 16px;
    width: 48%;
    border: 1px solid #999999;
    margin-bottom: 1.4285714rem;
    cursor: pointer;
}
.uddc76ContentTree-box{
    width: 24%;
    padding:0.714285714rem 5px;
    text-align: center;
}
.uddc76ContentTree-box span{
    font-size: 1rem;
  
}
.uddc76ContentTwo-box p {
    font-size: 1.4285714rem;
    text-align: left;
    margin-bottom: 10px;
    font-family: 'DMSans Bold';
}

.uddc76ContentTwo-boxtext {
    color: #1A2332;
    font-size: 1.142857142857143rem;
    text-align: left;
    line-height: 24px;
}

.uddc76ContentTwo-box-bortder p,.uddc76ContentTwo-box-bortder span,
.uddc76ContentTwo-box-bortder .uddc76ContentTwo-boxtext {
    color: #1290C9;
}

/deep/ .el-loading-mask {
    background-color: transparent !important;
}

.ucLkAbZbluc {
    background-color: #1290C9;
}

.messAgeClose {
    width: 48%;
    margin: auto;
    height: 3.42857143rem;
    background: #DEEFFF;
    border-radius: 1.714285714rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.42857143rem;

}

.messAgeClose-text {
    font-size: 1.42857143rem;
    color: #1290C9;
    display: flex;
    align-items: center;
}

.messAgeClose-text .icon {
    margin-right: 6px;
}

/*cssStart*/

/*cssEnd*/
</style>