import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
	searchType:'company', // 搜索类型 company，risk 页面需要根据类型更换要显示的字体内容等
	themeColor: '#1290C9', // 主题颜色
	themeRiskColor: '#8E86DD', // 主题颜色
  isMenu:true, // 搜索条件是否开启
	toolsConfig:{ // 工具栏
		"Optimize":{
			"isShow":true,
			"isActive":true
		},
		"menu":{
			"isShow":true,
			"isActive":true
		},"filter":{
			"isShow":true,
			"isActive":true
		},"select":{
			"isShow":true,
			"isActive":true
		},"edit":{
			"isShow":true,
			"isActive":true
		},"sort":{
			"isShow":true,
			"isActive":true
		},"collect":{
			"isShow":true,
			"isActive":true,
		},"saved":{
			"isShow":true,
			"isActive":true
		},"compare":{
			"isShow":true,
			"isActive":true
		},"export":{
			"isShow":true,
			"isActive":true
		},"download":{
			"isShow":true,
			"isActive":true
		},"update":{
			"isShow":true,
			"isActive":true
		},"reportError":{
			"isShow":true,
			"isActive":true
		},"share":{
			"isShow":true,
			"isActive":true
		}
	},
	changeList:[],
	collectList:[],
	equityType:'',
	powerArr:[],
	ColumnsArr:[],
	SearchagAin:false,
	labelCode:'',
	labelCodeName:'',
	SeachLabels:'',
	SeachLabelsCodeName:'',
	NotificationsList:[],
	leadesTags:[],
	//获课Labs
};
const getters = {
	changeleadesTags(value){
		state.leadesTags=value
	},
	changeNotificationsList(value){
		state.NotificationsList=value
	},
	changelabelCode(value){
		state.labelCode=value
	},
	changelabelCodeName(value){
		state.labelCodeName=value
	},
	changeSeachLabels(value){
		state.SeachLabels=value
	},
	changeSeachLabelsCodeName(value){
		state.SeachLabelsCodeName=value
	},

	changeSearchagAin(value){
		state.SearchagAin=value
	},
	changeColumnsArr(value){
		state.ColumnsArr=value
	},
	changeQuityType(value){
		state.equityType=value
	},
	changePowerArr(value){
		state.powerArr=value
	},
	changeThemeColor(value) { // 设置工具数据
    
		state.themeColor = value;
	},
	changeList(state) { // 设置工具数据
		return state.changeList;
	},
	collectList(value) { // 设置工具数据
    
		return state.collectList = value;
	}
};
const mutations = {
	SetleadesTags(state,arr){
		state.leadesTags = arr
	},
	SetNotificationsList(state,arr){
		state.NotificationsList = arr
	},
	SetlabelCode(state,type){
		state.labelCode = type
	},
	SetlabelCodeName(state,type){
		state.labelCodeName = type
	},
	SetSeachLabels(state,type){
		state.SeachLabels=type
	},
	SetSeachLabelsCodeName(state,type){
		state.SeachLabelsCodeName=type
	},
	SetarchagAin(state,type){
		state.SearchagAin = type
	},
	seColumnsArr(state,arr){
		state.ColumnsArr = arr
	},
	setThemColor(state, color) {
		state.themeColor = color
		// Vue.set( state.themeColor,color)
	},
	setSearchType(state,type){
		state.searchType = type
	},
	setEquityType(state,type){
		state.equityType = type
	},
	setPowerArr(state,arr){
		state.powerArr = arr
	},
  setTools(state,obj){
    
    let {name,key,value} = obj
    state.toolsConfig[name][key] = value
  },
  toggleMenu(state,obj){
    state.isMenu = obj
  },
  setchangeList(state,list){
	state.changeList = list
  },
  setcollectList(state,list){
	state.collectList = list
  },


};

const actions = {
	SetleadesTags({commit},arr){
		commit("SetleadesTags", arr);
		// console.log('触发了否 setPowerArr')
	},
	SetNotificationsList({commit},arr){
		commit("SetNotificationsList", arr);
		// console.log('触发了否 setPowerArr')
	},
	SetlabelCode({commit},type){
		commit("SetlabelCode", type);
		// console.log('触发了否 SetlabelCode')
	},
	SetlabelCodeName({commit},type){
		commit("SetlabelCodeName", type);
		// console.log('触发了否 SetlabelCode')
	},
	SetSeachLabels({commit},type){
		commit("SetSeachLabels", type);
	},
	SetSeachLabelsCodeName({commit},type){
		commit("SetSeachLabelsCodeName", type);
	},
	SetarchagAin({commit},type){
		commit("SetarchagAin", type);
		// console.log('触发了否 SetarchagAin')
	},
	seColumnsArr({commit},arr){
		commit("seColumnsArr", arr);
		// console.log('触发了否 setPowerArr')
	},
	setPowerArr({commit},arr){
		commit("setPowerArr", arr);
		// console.log('触发了否 setPowerArr')
	},
	setEquityType({commit},type){
		commit("setEquityType", type);
		// console.log('触发了否 setEquityType？？？？')
	},
	setThemColor({ commit }, color){
		commit("setThemColor", color);
	},
	setSearchType({ commit }, type){
		commit("setSearchType", type);
	},
  setTools({ commit }, obj){
		commit("setTools",obj);
	},
  toggleMenu({ commit }, obj){
    commit("toggleMenu",obj);
  },
  setchangeList({commit},list){
	commit("setchangeList",list);
  },
  setcollectList({commit},list){
	commit("setcollectList",list);
  }
};
export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
};
