<template>
    <!--srccomponentsgui_watermarkgui_watermarkvuehtmlStart-->

    <div id="defaultId1" class="   uc4b32">
        <div id="gee6e0" v-for="item in 100" class="   u376b3">{{value}}
        </div>
    </div>
    <!--srccomponentsgui_watermarkgui_watermarkvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",

        props: {
            // srccomponentsgui_watermarkgui_watermarkvuePropsStart

            // srccomponentsgui_watermarkgui_watermarkvuePropsEnd

            value: {
                type: String,
                default: "我是水印"
            }
        },

        mounted() {
            // defaultLoad
            // srccomponentsgui_watermarkgui_watermarkvueMountedStart

            // srccomponentsgui_watermarkgui_watermarkvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srccomponentsgui_watermarkgui_watermarkvueDataStart

                // srccomponentsgui_watermarkgui_watermarkvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_watermarkgui_watermarkvueMethodStart
            // default
            default () {

            }
            // srccomponentsgui_watermarkgui_watermarkvueMethodEnd

        },
        computed: {}
    };
</script>
<style>
    .srccomponentsgui_watermarkgui_watermarkvuecssStart {}

    .uc4b32 {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        pointer-events: none;
        overflow-x: hidden;
        flex-wrap: wrap;
    }

    .u376b3 {
        width: 95px;
        transform: rotate(60deg);
        color: rgba(84, 81, 81, 0.28);
        font-weight: 400;
        margin-bottom: 88px;
    }

    .srccomponentsgui_watermarkgui_watermarkvuecssEnd {}
</style>