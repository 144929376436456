import Vue from 'vue';
import VueRouter from 'vue-router'
import App from './App.vue';
// import router from './router';
import routes from './router/index.js'
import store from './store/index';
import axios from 'axios'; //引入axios
import ElementUI from 'element-ui';
import FBSignInButton from 'vue-facebook-signin-button'
import jwt_decode from 'jwt-decode';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.css'
import  marked from 'marked'; // 引入 marked

import locale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
// import ReLogin from './components/ReLogin.vue';
import GSignInButton from 'vue-google-signin-button'
import scroll from 'vue-seamless-scroll'
import VueAwesomeSwiper from 'vue-awesome-swiper';

import 'swiper/swiper-bundle.css'; // 确保引入样式
Vue.prototype.$axios = axios; //把axios挂载到vue上
Vue.prototype.$marked = marked;
Vue.use(ElementUI,{ locale });
Vue.use(FBSignInButton)
Vue.use(GSignInButton);
Vue.use(VueRouter)
Vue.use(scroll)
Vue.use(VueAwesomeSwiper);
// Vue.component('re-login', ReLogin);

import '@/assets/css/font.css';
// 引入样式表
import '@/assets/css/styles.css';
// 自适应css
import '@/assets/css/media.css';
Vue.config.productionTip = false;

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;


Vue.prototype.$EventBus = new Vue();
import world from '../node_modules/echarts/map/json/world.json';

echarts.registerMap('world', world);
import './filters';
// 下载
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);
const router = new VueRouter({routes})

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // 检查点击是否在 el 元素及其子元素之外
            if (!(el == event.target || el.contains(event.target))) {
                // 如果是，则调用在指令中提供的方法
                vnode.context[binding.expression]();
            }
        }
        // 在 document.body 上添加点击事件监听器
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        // 移除事件监听器
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});



// 全局获取缓存数据
Vue.prototype.resetSetItem = function (key, newVal) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            sessionStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        },
    };
    // if (key === 'menuItem') {
    // return storage.setItem(key, newVal);
    // }
    if (key === 'token') {
        return storage.setItem(key, newVal);
    }
}
  
new Vue({
    router,
        store,

        beforeCreate() {
            Vue.prototype.$bus = this;
        },
        render: h => h(App),
    }).$mount('#app');

    // const loadGoogleAPI = () => {
    //     const script = document.createElement('script');
    //  script.src = 'https://apis.google.com/js/api.js'; // 加载 gap
    //     script.async = true;
    //     script.defer = true;
    //     script.onload = () => {
    //       window.gapi.load('auth2', () => {
    //         window.gapi.auth2.init({
    //           client_id: '929082852079-ccb9kp2hd64ub1dk4slmensrj5cbshin.apps.googleusercontent.com', // 替换为你的客户端 ID
    //         });
    //       });
    //     };
    //     document.head.appendChild(script);
    //   };
      
    //   loadGoogleAPI();
    // const loadGoogleIdentityServices = () => {
    //     const script = document.createElement('script');
    //     script.src = 'https://accounts.google.com/gsi/client; frame-src';
    //     script.async = true;
    //     script.defer = true;
    //     script.onload = () => {
    //         // 在这里初始化 Google Identity Services
    //         window.google.accounts.id.initialize({
    //             client_id: '929082852079-ccb9kp2hd64ub1dk4slmensrj5cbshin.apps.googleusercontent.com', // 替换为你的客户端 ID
    //         });
    //     };
    //     document.head.appendChild(script);
    // };
    
    // loadGoogleIdentityServices();
// new Vue({
//     el: '#app',
//     router,
//     render: h => h(App)
//   })