import axios from 'axios'
import bus from '../components/bus'
//这里引用了element的loading全屏加载
import { Loading, Message } from 'element-ui'
axios.defaults.withCredentials = true
const service = axios.create({
  // baseURL: '/devapi',
  timeout: 240000 // 设置请求超时时间
})
var loading

// 请求拦截器
service.interceptors.request.use(
  config => {

    const token = localStorage.getItem('token')
    config.headers.Authorization = token
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if(isMobile){
      config.headers.usaged = 1
    }else{
      config.headers.usaged = 2
    }
   
 if(config.headers.istoken==false){
  config.headers.Authorization=''
 }
 
    if (config.isLoading) {
    } else {
      loading = Loading.service({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.7)',
        customClass: 'request-loading'
      })

    }


    // 在请求发送之前做一些处理


    if (config.method == 'post' && config.type == 'upload') {
      //  config.headers['Content-Type'] = 'multipart/form-data'
      config.headers['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundaryTn6YTaenhDx8ypFK'
    } else if (config.method == 'post' && config.type == 'download') {
      // 导出csv文件
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      config.responseType = 'blob'
    } else if (config.method == 'post' && config.headers.istrue != 'zhifu') {
      // config.headers['Content-Type'] = 'multipart/form-data'
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
      for (var key in config.data) {
        if (config.data[key] === '') {
          delete config.data[key]
        }
      }
    } else if (config.method == 'put' && config.headers.istrue == 'genxin') {

      config.headers['Content-Type'] = 'application/json'
    } else {

      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
   if(config.url.includes('/third/apple/callback')){
     config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
   }
    return config
  },
  error => {
    loading.close()

    // 发送失败
    return Promise.resolve(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    loading.close()

   
    // dataAxios 是 axios 返回数据中的 data
    if (response.config.type == 'download') {
      return response
    } else {

      const dataAxios = response.data   
      
      // 这个状态码是和后端约定的
      if (response.status == 401) {
        Message.closeAll()
        Message({
          message: 'Please Login in!',
          type: 'error',
          duration: 3000
        })
        sessionStorage.clear()
        setTimeout(() => {
          window.location.href = '/'
        }, 3000);
        localStorage.removeItem('token')
     
        bus.$emit('handleShowLogin', true)// 跳转到登录
        localStorage.removeItem("userInfoImg");
      }
      // else if (dataAxios.code == 402 && dataAxios.msg != " Not Power") {
      //   console.log('402', dataAxios.code, dataAxios.msg)
      //   Message({
      //     message: dataAxios.msg,
      //     type: 'error',
      //     duration: 3000
      //   })
      // }
      return dataAxios
    }
  },
  (error) => {
    loading.close()
    
    // console.log('error-------------', error, '---', String(error).toLowerCase().indexOf('timeout'))
    let errNum = String(error).toLowerCase().indexOf('timeout')
    let isReLogin = String(error).includes('403')
    if(isReLogin) {
     Message({
          message: 'Your account is logged in on another device. If you lose your password, please handle it promptly. You can also choose to log in again',
          type: 'error',
          duration: 3000
        })
    
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      window.location.href = '/ '
      localStorage.removeItem("userInfoImg");
      localStorage.removeItem("userInfo");
    } else {
      if( String(error).includes('401')) {
     
        Message({
          message: 'Please Login in!',
          type: 'error',
          duration: 3000
        })
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
        window.location.href = '/ '
        localStorage.removeItem("userInfoImg");
        localStorage.removeItem("userInfo");
      }
      if (error && errNum > -1) {
        bus.$emit('pageLoadingStatus', false)
      }
    }
    return Promise.reject(error)
  }
)

export default service
