// 用户中心模块

import axios from './request'
//定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./
const phone =mobil.test(currentUrl) 
const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8066/csi'//new-测试
let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'https://www.csibizsearch.com/csi'//生产
// let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
// if(phone){
//   api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
// }


//个人中心统计仪表板
export const getDashboard = params => {
  return axios.get(api + '/user/getDashboard', params)
}
//个人中心工作台列表
export const getLoginGlance = params => {
  return axios.get(api + '/gleagle/csiv4/getLoginGlance?'+ params)
}
export const getOrdersPayList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersPayList', params)
}
export const editUserBalan = params => {
  return axios.post(api + '/gleagle/userBalance/editUserBalanceIntegral', params)
}
export const applyUserBalan = params => {
  return axios.post(api + '/gleagle/userBalance/applyUserBalanceIntegral', params)
}
export const getOrderList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersList', params)
}
export const getOrderview = params => {
  return axios.post(api + '/gleagle/orders/view', params)
}

export const getRechargeList = params => {
  return axios.post(api + '/gleagle/orders/getUserPayList', params)
}
export const getLoginDuration = params => {
  return axios.get(api + '/gleagle/csiv4/getLoginDuration?'+ params)
}
export const getLogDurDetails = params => {
  return axios.get(api + '/gleagle/csiv4/getLogDurDetails?'+ params)
}
//线上报告pdf
export const getPdfAddress = params => {
  return axios.get(api + '/gleagle/file/getPdfAddress/'+ params)
}
// 香港pdf
export const getPdfhk_pdf = params => {
  return axios.get(api + '/gleagle/file/pdf/' + params)
}
//是否购买报告
export const getPdfPurchase = params => {
  return axios.get(api + '/gleagle/orders/purchase?'+ params,{  isLoading: true})
}

//个人中心账户设置
export const settingAccount = params => {
  return axios.post(api + '/gleagle/csiv4/settingsAccount', params)
}
//个人中心用户公司信息
export const updateAccount = params => {
  return axios.post(api + '/gleagle/csiv4/updateAccount', params)
}
// 个人用户账单信息
export const selectInfo = params => {
  return axios.get(api + '/gleagle/common/verifyCompanyBilling/selectInfo' )
}
// 个人用户账单新增信息
export const saveInfo = params => {
  return axios.post(api + '/gleagle/common/verifyCompanyBilling/saveInfo', params)
}
// 个人用户账单修改信息
export const updateInfo = params => {
  return axios.post(api + '/gleagle/common/verifyCompanyBilling/updateInfo', params)
}
//个人中心联系人信息
export const updateContact = params => {
  return axios.post(api + '/gleagle/csiv4/updateContact', params)
}

export const getpersonnel = params => {
  return axios({
    url: api + '/gleagle/people/searchDetail',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export const getCommonAssist = params => {
  return axios({
    url: api + '/gleagle/common/assist',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 个人中心工作台列表3和我的数据页面
export const register = params => {
  return axios.post(api + '/gleagle/user/addUser', params)
}
export const getOrdersList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersList', params)
}
// 个人中心我的足迹
export const getPortfolio = params => {
  return axios({
    url: api + '/gleagle/csiv4/getPortfolio',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 收藏列表

export const getCollectionList = params => {
  return axios({
    url: api + '/collection/getCollectionList',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 获取显示工具列表

export const getqueryList = params => {
  return axios({
    url: api + '/tools/query',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 获取移除工具列表

export const getqueryremoveList = params => {
  return axios({
    url: api + '/tools/remove',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 获取工具添加

export const getqueryaddList = params => {
  return axios({
    url: api + '/tools/add',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 添加收藏

export const addItems = data => {
  return axios({
    url: api + '/collection/addItems',
    method: 'post',
    data
  })
}
// 批量取消收藏

export const removeItems = data => {
  return axios({
    url: api + '/collection/removeItems',
    method: 'post',
    data
  })
}
// 查看收藏夹内容
export const showItems = params => {
  return axios({
    url: api + '/collection/showItems',
    method: 'GET',
    params,

  })
}
// 创建收藏佳
export const creates = params => {
  return axios({
    url: api + '/collection/create',
    method: 'GET',
    params,

  })
}
// 个人中心菜单获取
export const getUserCenterMenuList = params => {
  return axios({
    url: api + '/user/getUserCenterMenuList',
    method: 'GET',
    params,

  })
}
// 个人中心菜单获取
export const searchUserCenterMenuList = params => {
  return axios({
    url: api + '/user/searchUserCenterMenuList',
    method: 'GET',
    params,

  })
}
// 获取通知列表
export const getNotificationList = params => {
  return axios({
    url: api + '/notification/getNotificationList',
    method: 'GET',
    params,

  })
}
// 获取新闻列表
export const getUserNews = params => {
  return axios({
    url: api + '/notification/getUserNews',
    method: 'GET',
    params,

  })
}
// 获取新闻列表
export const searchUserNews = params => {
  return axios({
    url: api + '/notification/searchUserNews',
    method: 'GET',
    params,

  })
}
// 标记读取消息
export const readNotification = data => {
  return axios({
    url: api + '/notification/readNotification',
    method: 'post',
    data

  })
}
//全部标记为已读
export const markAllAsRead = params => {
  return axios({
    url: api + '/notification/markAllAsRead',
    method: 'GET',
    params,

  })
}
//全部按照titile查找
export const searchByTitle = params => {
  return axios({
    url: api + '/notification/searchByTitle',
    method: 'GET',
    params,

  })
}
// Database
export const getUserActionLogPage = params => {
  return axios.post(api + '/myDatabase/getUserActionLogPage', params)
}
// 上传图片
export const UploadImg = params => {
  return axios.post(api + '/usercenter/avatar', params,{ type: "upload" })
}
// 上传产品图片
export const UploadProductImg = params => {
  return axios.post(api + '/businessActivities/avatarProduct', params,{ type: "upload" })
}
// 删除图片
export const clearUserImage = params => {
  return axios.delete(api + '/usercenter/clearUserImage', params)
}
// 图像下载
// export const getUserActionLogPage = params => {
//   return axios.post(api + '/myDatabase/getUserActionLogPage', params)
// }
export const getbillingAndPaymentStatistics = params => {
  return axios.post(api + '/billingAndPayment/billingAndPaymentStatistics', params)
}

export const getUserRechargesPage = params => {
  return axios.post(api + '/billingAndPayment/getUserRechargesPage', params)
}
//用户扣点记录表单
export const getUserConsumptionPage = params => {
  return axios.post(api + '/billingAndPayment/getUserConsumptionPage', params)
}
export const getmyDatabaseStatistics = params => {
  return axios.post(api + '/myDatabase/myDatabaseStatistics', params)
}

export const downloadOnlinePdf = params => {
  return axios({
    url: api + '/myDatabase/downloadOnlinePdf/'+params,
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
   responseType: 'arraybuffer'
  })
}
//导出
export const exportData = data => {
  return axios({
    isLoading: true,
    url: api + '/findPartnerPc/exportData',
    method: 'POST',
    data,
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
   responseType: 'arraybuffer',
  
  })
}
// 产品模糊搜索
export const getLeadsProductFilterList = params => {
  return axios.post(api + '/findPartner/getLeadsProductFilterList', params,{  isLoading: true})
}
// 更新表头
export const updateLeadsAnswersHead = params => {
  return axios.post(api + '/findPartner/updateLeadsAnswersHead', params,{  isLoading: true})
}

// 
export const saveUserConsumption = params => {
  return axios.post(api + '/consume/saveUserConsumption', params)
}
// 获取问题集
export const getLeadsQuestions = params => {
  return axios.post(api + '/findPartner/getLeadsQuestions', params,{  isLoading: true})
}
// 保存问题集

export const saveLeadsAnswers = params => {
  return axios.post(api + '/findPartner/saveLeadsAnswers', params,{  isLoading: true})
}

// 批量Updade
export const saveUserConsumptionBatch = params => {
  return axios.post(api + '/consume/saveUserConsumptionBatch', params)
}
//批量查询
export const getUserConsumptionBatch = params => {
  return axios.post(api + '/consume/getUserConsumptionBatch', params)
}
export const whetherDeductPoints = params => {
  return axios.post(api + '/consume/whetherDeductPoints', params)
}
export const getOfflinePdf = params => {
  return axios.get(api + '/consume/getOfflinePdf/'+ params)
}

export const gettabList = params => {
  return axios({
    url: api + '/tab/tabList',
    method: 'GET',
    params,
  })
}

// export const getTabListOneLayer = params => {
//   return axios.get(api + '/tab/getTabListOneLayer/' + params)
// }

export const getTabListOneLayer = params => {
  return axios({
    url: api + '/tab/getTabListOneLayer',
    method: 'GET',
    params,
  })
}

// 
// 获取发票pdg
export const getPdf = params => {
  return axios.get(api + '/gleagle/file/getPdfAddress/'+ params)
}
//下载发票
export const getInvoicePdfAddress = params => {
  return axios({
    url: api + '/billingAndPayment/getInvoicePdfAddress',
    method: 'GET',
    params,

  })
}
export const getUserRechargesPdfAddress = params => {
  return axios.get(api + '/billingAndPayment/getUserRechargesPdfAddress/'+ params)
}

// 充值记录下载
// export const getUserRechargesPdfAddress = params => {
//   return axios({
//     url: api + '/',
//     method: 'GET',
//     params,

//   })
// }
// 发票信息
export const getInvoiceInformation = params => {
  return axios.post(api + '/billingAndPayment/getInvoiceInformation', params)
}
// 发票信息新增
export const saveInvoiceInformation = params => {
  return axios.post(api + '/billingAndPayment/saveInvoiceInformation', params)
}
// 发票信息更新
export const updateInvoiceInformation = params => {
  return axios.post(api + '/billingAndPayment/updateInvoiceInformation', params)
}


export const getOrderInvoiceList = params => {
  return axios.post(api + '/gleagle/orders/getOrderInvoiceList', params)
}
//个人中心-groups-分页列表

export const getGroupList = params => {
  return axios({
    isLoading: true,
    url: api + '/org/userGroupList',
    method: 'GET',
    params,

  })
}
export const userGroupDelete = params => {
  return axios({
    url: api + '/org/userGroupDelete',
    method: 'GET',
    params,

  })
}
export const getRoleList = params => {
  return axios({
    isLoading: true,
    url: api + '/org/getRoleList',
    method: 'GET',
    params,

  })
}
export const getUserList = params => {
  return axios({
    isLoading: true,
    url: api + '/org/getCompanyUser',
    method: 'GET',
    params,

  })
}

export const getPermissionList = params => {
  return axios.get(api + '/org/getPermissionList', params)
}

export const addRole = params => {
  return axios.post(api + '/org/addRole', params)
}
export const editRoleInfo = params => {
  return axios.post(api + '/org/updateRole', params)
}
export const getaddGroup = params => {
  return axios.post(api + '/org/userGroupAdd', params)
}
export const userGroupUpdate = params => {
  return axios.post(api + '/org/userGroupUpdate', params)
}
export const getGroupTreeList = params => {
  return axios.get(api + '/gleagle/common/deptInfo/getGroupTreeList', params)
}
// 修改状态
export const editSonUserInfoStatus = params => {
  return axios.post(api + '/gleagle/userInfo/editSonUserInfoStatus', params)
}
// 个人中心我的足迹
export const editSonUserInfo = data => {
  return axios({
    url: api + '/org/userDistributeGroupRole',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      istrue: "zhifu"
    }
  })
}
// export const editSonUserInfo = params => {
//   return axios.post(api + '/org/userDistributeGroupRole', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",}})
// }
export const addSonUserInfo = params => {
  return axios.post(api + '/gleagle/userInfo/addSonUserInfo', params)
}

export const getRoleById = params => {
  return axios.get(api + '/org/getRoleAndPrivilege?'+ params)
}
export const deleteRole = params => {
  return axios.get(api + '/org/deleteRole?'+ params)
}
// 我的服务列表
export const creditServiceList = params => {
  return axios.post(api + '/gleagle/creditService/creditServiceList', params)
}
// 服务列表查询条件
export const getTypeAndStatusList = params => {
  return axios.post(api + '/gleagle/creditService/getTypeAndStatusList', params)
}
// dabase统计
export const getMyDataBaseCount = params => {
  return axios.get(api + '/gleagle/orders/getMyDataBaseCount', params )
}
// Portfolio统计
export const getPortfolioCount = params => {
  return axios.get(api + '/gleagle/collection/getPortfolioCount', params)
}
// 封装websouct连接接口
export const createWebSocketConnection = (usercode) => {
  return new Promise((resolve, reject) => {
// 测试
    // const url = `ws://52.81.219.235:8088/webSocket/gleagle/${usercode}`;
    // 生产
    const url = `wss://csibizsearch.com/webSocket/gleagle/${usercode}`;
    const socket = new WebSocket(url);
   
      resolve(socket);

    const socketInfo = {
      url: socket.url,
      readyState: socket.readyState,
      // 其他需要存储的信息
    };
        sessionStorage.setItem('socket',JSON.stringify(socketInfo))
   

    socket.onmessage = (event) => {
    };

    socket.onerror = (event) => {
      reject(event);
    };
    // socket.close() 关闭连接
  
  });
};