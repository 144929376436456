// 引入封装好的axios
import axios from './request'
// 定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./
const phone =mobil.test(currentUrl) 
const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8066/csi'//new-测试
let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'https://www.csibizsearch.com/csi'//生产
// let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
// if(phone){
//   api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
// }

//登录

export const doLogin = params => {
  return axios.post(api + '/user/login', params)
}
//goole登录
export const thirdLoginGl = data => {
  return axios({
    url: api + '/user/thirdLoginGl',
    method: 'post',
    data
  })
}
//goole登录
export const thirdLoginAp = data => {
  return axios({
    url: api + '/user/thirdLoginAp',
    method: 'post',
    data
  })
}
export const LoginOut = params => {
  return axios.post(api + '/user/loginOut', params)

  // return axios({
  //   url: api + '/gleagle/user/login',
  //   method: 'POST',
  //   params,
  //   headers: {
  //     'Content-Type': 'application/json;charset=UTF-8'
  //   }
  // })
}
//注册
export const register = params => {
  return axios.post(api + '/user/register', params)
}
// 获取产品信息
export const getProductList = params => {
  return axios.post(api + '/pay/getProductList', params)
}
export const failedPay = params => {
  return axios.post(api + '/pay/failedPay', params)
}
export const sendEmailCode = params => {
  return axios.post(api + '/user/sendEmailVerification', params)
}
export const changePwd = params => {
  return axios.post(api + '/user/changePwd', params)
}
//忘记密码校验code
export const checkForgotCode = params => {
  return axios.post(api + '/user/tickNextStep', params)
}
//忘记密码校验code
export const changeUserAccount = params => {
  return axios.post(api + '/user/changeUserAccount', params)
}
// 个人企业认证
export const handleAccountOwnership = params => {
  return axios.post(api + '/user/handleAccountOwnership', params)
}
// 设置联系人
export const setContactPerson = params => {
  return axios.post(api + '/usercenter/setContactPerson', params)
}
// 获取联系人
export const getUserContactPersons = params => {
  return axios({
    url: api + '/usercenter/getUserContactPersons',
    method: 'GET',
    params
  })
}
// 注销
export const cancelAccount = params => {
  return axios({
    url: api + '/user/cancelAccount',
    method: 'GET',
    params
  })
}
//还未完成任务
export const showTasksForCancelAccount = params => {
  return axios({
    url: api + '/user/showTasksForCancelAccount',
    method: 'GET',
    params
  })
}
// 放弃任务
export const giveUpTasksForCancelAccount = data => {
  return axios({
    url: api + '/user/giveUpTasksForCancelAccount',
    method: 'post',
    data
  })
}
// 月份获取时间
export const showLoginHistoryDataByYM = params => {
  return axios({
    url: api + '/user/showLoginHistoryDataByYM',
    method: 'GET',
    params
  })
}
export const setLoginRetentionTime = data => {
  return axios({
    url: api + '/usercenter/setLoginRetentionTime',
    method: 'post',
    data
  })
}
//忘记密码
export const forgetPassword = params => {
  return axios.post(api + '/user/retrievePassword', params)
}
