<template>
    <!--srccomponentsgui_tabsgui_tab_itemvuehtmlStart-->

    <div id="defaultId1" @click="slctMenu()" :class="value==active_id?'ua809b':''" class=" uGuqXzB" key="all" active="true" name="header">
        <div id="gb8a45" :class="isDisabel?'disabel':''" class=" ue56a2">
            <slot id="c97269" name="icon">
            </slot>
        </div>
        <div id="g2c044" :class="isDisabel?'disabel':''" class="  content">
            <slot id="c7ff48">
            </slot>
        </div>
        
    </div>
    <!--srccomponentsgui_tabsgui_tab_itemvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd


    // interfaceCode
    export default {
        name: '',
        props: {
            // srccomponentsgui_tabsgui_tab_itemvuePropsStart

            // 是否触发点击
            isNoClick: {
                type: Boolean,
                default: false
            },
            // 是否不可点
            isDisabel: {
                type: Boolean,
                default: false
            },
            // 索引id
            active_id: {
                type: String,
                default: ''
            },
            // 激活的索引名称
            value: {
                type: String,
                default: ''
            }
            // srccomponentsgui_tabsgui_tab_itemvuePropsEnd


        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_tabsgui_tab_itemvueMountedStart



            // srccomponentsgui_tabsgui_tab_itemvueMountedEnd

        },
        components: {

        },
        data() {
            return {
                // insertData
                // srccomponentsgui_tabsgui_tab_itemvueDataStart

                // srccomponentsgui_tabsgui_tab_itemvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_tabsgui_tab_itemvueMethodStart
            // 点击选中
            slctMenu() {
                if (this.isDisabel) {
                    return
                }

                this.$emit('input', this.active_id)
            }, // default
            default () {



            }
            // srccomponentsgui_tabsgui_tab_itemvueMethodEnd

        },
        computed: {}
    }
</script>
<style>
    .srccomponentsgui_tabsgui_tab_itemvuecssStart {}

    .uGuqXzB {
        display: flex;
        align-items: center;
        border-bottom-left-radius: 0.3em;
        border-top-left-radius: 0.3em;
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
        color: #1A2332;
        cursor: pointer;
        margin-left: 2.14rem;
        /* margin-left: 1em; */
        overflow-x: hidden;
        width: max-content;
    }

    .ue56a2 {
        /* padding-top: 0.3em;
        padding-left: 0.7em;
        padding-bottom: 0.3em; */
        margin-top: 0.4rem;
    }

    .content {
        /* padding-top: 0.2em;
        padding-left: 0.4em;
        padding-bottom: 0.2em;
        padding-right: 0.4em; */
        font-size: 1.1428575rem;
        margin-left: 0.35rem;
        margin-top: 0.21rem;
    }
/deep/ .el-dropdown{
    font-size: 1.1428575rem;
}
    .u7041b {
        display: none;
    }

    .ua809b {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .uGuqXzB .disabel path {
        cursor: no-drop;
        fill: #999999;
    }

    .uGuqXzB .disabel {
        color: #999999;
        cursor: no-drop;
    }

    .ue56a2 path {
        margin-right: 0.4rem;
        fill: #1A2332
    }

    .content path {
        /* fill: #1A2332; */
    }

</style>