<template>
    <div>
        <!-- 忘记密码弹框 -->
        <el-dialog :visible.sync="loginAndCreateDialog" center :close-on-click-modal="true"  @closed="closeDialog()"
            custom-class="dialog_normallogin" :append-to-body="true">
            <div class="dialog_cont forgotDialog_count">
                <div class="count_con">
                    <div class="left_logo">
                        <img src="../assets/images/left-logo.png" alt="" />
                    </div>
                    <div :class="['forgotDialog-box', step1Show ? '' : 'perfect_boxA']">
                        <div style="text-align: center">
                            <div style="text-align: center;position: relative;">
                                <p class="popTitletwo-text">Complete your Profile</p>
                                <svg t="1729654941103" @click="closeDialog()"
                                    style="position: absolute;right: 0px;top: 3px;" class="icon" viewBox="0 0 1024 1024"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9659" width="24" height="24">
                                    <path
                                        d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
                                        fill="#1A2332" p-id="9660"></path>
                                </svg>
                            </div>

                            <div class="popTitle-head">Select your account type</div>
                            <div v-if="step1Show" class="popTitle-img flex_center">
                                <div class="prtfect_item">
                                    <div class="img-left active_item">
                                        <img src="@/assets/images/peoplactive.png" alt="" />
                                    </div>
                                    <div class="prtfect_item_desc active_desc">Individual Account</div>
                                </div>
                                <div class="prtfect_item">
                                    <div class="img-left">
                                        <img src="../assets/images/bus.png" @click="Checkrenzheng(1)" alt="" />
                                    </div>
                                    <div class="prtfect_item_desc">Group Account</div>
                                </div>
                            </div>
                            <div v-if="step2Show" class="popTitle-img flex_center">
                                <div class="prtfect_item">
                                    <div class="img-left">
                                        <img src="@/assets/images/peopl.png" alt="" @click="Checkrenzheng(2)" />
                                    </div>
                                    <div class="prtfect_item_desc">Individual Users</div>
                                </div>
                                <div class="prtfect_item">
                                    <div class="img-left active_item">
                                        <img src="../assets/images/busacitve.png" alt="" />
                                    </div>
                                    <div class="prtfect_item_desc active_desc">Company Users</div>
                                </div>
                            </div>
                        </div>
                        <el-form :model="forgotForm" :rules="step1Show ? forgotFormRules : forgotFormRulesA" ref="forgotForm">
                            <div class="step1" v-show="step1Show">
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="firstName" label="First Name">
                                            <el-input autocomplete="off" placeholder="Enter First Name"
                                                v-model="forgotForm.firstName" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="countryCode" label="Country/Region" style="width: 100%">
                                            <el-select style="width: 100%" v-model="forgotForm.countryCode"
                                                placeholder="Country/Region" class="fin_top_sel">
                                                <el-option style="width: 100%" v-for="item in countryOptions"
                                                    :key="item.countryCode" :label="item.nameEn"
                                                    :value="item.countryCode"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="lastName" class="" label="Last Name">
                                            <el-input autocomplete="off" v-model="forgotForm.lastName"
                                                placeholder="Enter Last Name" maxlength="50"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="address1" label="Address">
                                            <el-input autocomplete="off" v-model="forgotForm.address1" class="send_code_mar"
                                                placeholder="Enter Address" maxlength="20"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%" class="">
                                        <el-form-item prop="phoneNumber" label="Phone Number">
                                            <el-input autocomplete="off" placeholder="Enter Phone Number"
                                                v-model="forgotForm.phoneNumber" class="send_code_mar">
                                                <!-- <el-select style="width: 100px;" v-model="select" slot="prepend"
                                                    placeholder="">
                                                    <el-option v-for="item in countryOptions" :key="item.countryCode"
                                                        :label="item.nameEn" :value="item.countryCode"></el-option>
                                                </el-select> -->
                                                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="city" label="City">
                                            <el-input autocomplete="off" v-model="forgotForm.city"
                                                class="send_code_mar" placeholder="Enter City" maxlength="20"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="email" label="Email">
                                            <el-input autocomplete="off" placeholder="Enter Email"
                                                v-model="forgotForm.email" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="zipCode" label="Zip Code">
                                            <el-input autocomplete="off" v-model="forgotForm.zipCode" class="send_code_mar"
                                                placeholder="Enter Zip Code" maxlength="20"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>

                                <el-form-item class="btn_mar_top">
                                    <el-button class="btn_form_log" :class="{ blue: true }"
                                        @click="submitForgotForm('forgotForm')">Save</el-button>
                                </el-form-item>
                            </div>
                            <div class="step2" v-show="step2Show">
                                <div class="flex_between_box">
                             
                                    <div style="width: 45%">
                                        <el-form-item prop="firstName" label="First Name">
                                            <el-input autocomplete="off" placeholder="Enter First Name"
                                                v-model="forgotForm.firstName" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                               
                                    <div style="width: 45%">
                                        <el-form-item prop="countryCode" label="Country/Region" style="width: 100%">
                                            <el-select style="width: 100%" v-model="forgotForm.countryCode"
                                                placeholder="Country/Region" class="fin_top_sel">
                                                <el-option style="width: 100%" v-for="item in countryOptions"
                                                    :key="item.countryCode" :label="item.nameEn"
                                                    :value="item.countryCode"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="lastName" class="" label="Last Name">
                                            <el-input autocomplete="off" v-model="forgotForm.lastName"
                                                placeholder="Enter Last Name" maxlength="50"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="address1" label="Address">
                                            <el-input autocomplete="off" v-model="forgotForm.address1" class="send_code_mar"
                                                placeholder="Enter Address" maxlength="20"></el-input>
                                        </el-form-item>
                                    </div>
                               
                                  
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%" >
                                        <el-form-item prop="phoneNumber" label="Phone Number">
                                            <el-input autocomplete="off" placeholder="Enter Phone Number"
                                                v-model="forgotForm.phoneNumber" class="input-with-select">
                                                <!-- <el-select style="width: 100px;" v-model="select" slot="prepend"
                                                    placeholder="">
                                                    <el-option v-for="item in countryOptions" :key="item.countryCode"
                                                        :label="item.nameEn" :value="item.countryCode"></el-option>
                                                </el-select> -->
                                                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="city" label="City">
                                            <el-input autocomplete="off" v-model="forgotForm.city"
                                                class="send_code_mar" placeholder="Enter City" maxlength="20"></el-input>
                                        </el-form-item>
                                    </div>
                                
                                
                                </div>
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="email" label="Email">
                                            <el-input autocomplete="off" placeholder="Enter Email"
                                                v-model="forgotForm.email" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div style="width: 45%">
                                        <el-form-item prop="zipCode" label="Zip Code">
                                            <el-input autocomplete="off" placeholder="Enter Zip Code"
                                                v-model="forgotForm.zipCode" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                
                                </div>
                                
                                <div class="flex_between_box">
                                    <div style="width: 45%">
                                        <el-form-item prop="companyName" label="Organization Name">
                                            <el-input autocomplete="off" placeholder="Enter Organization Name"
                                                v-model="forgotForm.companyName" class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    
                                </div>

                                <el-form-item class="btn_mar_topA">
                                    <el-button class="btn_form_log" :class="{ blue: true }"
                                        @click="submitForgotForm('forgotForm')">Save</el-button>
                                </el-form-item>
                            </div>
                            <!-- <div class="step3">  Back to   <span  class="forgot_txt"  @click="goToLogin()"> Login.</span> </div> -->
                        </el-form>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { handleAccountOwnership } from "@/api/api.js";

import { mapGetters, mapActions, mapState } from "vuex";
import bus from "./bus";
// import axios from 'axios';
export default {
    props: {
        loginAndCreatePop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            token: "users/token",
        }),
        ...mapState({
            token: "users/token",
        }),
        type() {
            return this.inputType ? "text" : "password";
        },
        loginAndCreateDialog: {
            get() {
                return this.loginAndCreatePop;
            },
            set(newValue) {
                this.$emit("update:loginAndCreatePop", newValue);
            },
        },
    },
    data() {

        return {
            step1Show: true,
            step2Show: false,
            // step1Show: false,
            // step2Show: true,
            select: "",
            forgotForm: {
                type: '',
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                countryCode: "",
                address1: "",
                city: "",
                zipCode: "",
                companyName: "",
            },
            countryOptions: [],
            forgotFormRules: {
                firstName: [
                    {
                        required: true,
                        message: 'Please enter the first name',
                        trigger: 'blur',
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: 'Please enter the last name',
                        trigger: 'blur',
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: 'Please enter the phone number',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please enter the email',
                        trigger: 'blur',
                    },
                ],
                countryCode: [
                    {
                        required: true,
                        message: 'Please enter the country code',
                        trigger: 'change',
                    },
                ],
                address1: [
                    {
                        required: true,
                        message: 'Please enter the address line 1',
                        trigger: 'blur',
                    },
                ],
                city: [
                    {
                        required: true,
                        message: 'Please enter the city',
                        trigger: 'blur',
                    },
                ],
                zipCode: [
                    {
                        required: true,
                        message: 'Please enter the zip code',
                        trigger: 'blur',
                    }, 
                ]
            },
            forgotFormRulesA: {
                companyName: [
                    {
                        required: true,
                        message: 'Please enter the organization name',
                        trigger: 'blur',
                    },
                ],
                firstName: [
                    {
                        required: true,
                        message: 'Please enter the first name',
                        trigger: 'blur',
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: 'Please enter the last name',
                        trigger: 'blur',
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: 'Please enter the phone number',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please enter the email',
                        trigger: 'blur',
                    },
                ],
                countryCode: [
                    {
                        required: true,
                        message: 'Please enter the country code',
                        trigger: 'change',
                    },
                ],
                address1: [
                    {
                        required: true,
                        message: 'Please enter the address line 1',
                        trigger: 'blur',
                    },
                ],
                city: [
                    {
                        required: true,
                        message: 'Please enter the city',
                        trigger: 'blur',
                    },
                ],
                zipCode: [
                    {
                        required: true,
                        message: 'Please enter the zip code',
                        trigger: 'blur',
                    }, 
                ]
            },
        };
    },
    created() { },
    mounted() {
        this.getCountry();
    },

    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        Checkrenzheng(value) {
            if (value == 1) {
                this.step2Show = true
                this.step1Show = false
            } else {
                this.step2Show = false
                this.step1Show = true

            }
            this.forgotForm = {

                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                countryCode: "",
                address1: "",
                city: "",
                zipCode: "",
                companyName: "",
            }
            this.select = ''
        },

        //获取国家
        submitForgotForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.step1Show) {
                        this.forgotForm.type = 1
                    } else {
                        this.forgotForm.type = 2
                    }
                    let params = this.forgotForm

                    handleAccountOwnership(params).then(res => {
                        if (res.code == -1) {
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: "error",
                            });
                        } else {
                            localStorage.setItem('renzheng', true)
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: "success",

                            });
                            this.closeDialog()

                        }
                    })
                } else {
                    return false;
                }
            });
        },
        getCountry() {
            this.countryOptions = JSON.parse(
                localStorage.getItem("countryList")
            )[0]?.countryList;
        },
        changeCountry() {
            this.forgotForm.city = "";
            this.forgotForm.city = "";
            this.forgotForm = [];
        },
        //关闭弹窗初始化
        closeDialog() {
            this.$emit("startTime", true);
            this.loginAndCreateDialog = false
            this.forgotForm = {

                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                countryCode: "",
                address1: "",
                city: "",
                zipCode: "",
                companyName: "",
            }
            // this.$nextTick(() => {
            //     if (this.$refs.createAccountForm !== undefined)
            //         this.$refs.createAccountForm.resetFields();
            //     if (this.$refs.forgotForm !== undefined)
            //         this.$refs.forgotForm.resetFields();
            // });
        },
        //提交忘记表单

    },
};
</script>
<style scoped>
.person_type {
    font-size: 1.2857142857142858rem;
    color: #1A2332;
    line-height: 1.4285714285714286rem;
}
.warning_txt {
    margin-top: 0.7142857142857143rem;
    margin-bottom: 2.142857142857143rem;
    font-size: 0.8571428571428571rem;
    line-height: 1.1428571428571428rem;
}

.btn_mar_top {
    margin-top: 2.857142857142857rem;
    text-align: center;
}

.btn_mar_topA {
    margin-top: 0;
    margin-bottom: 0 !important;
    text-align: center;
}

.forgotDialog_count {
    background-image: url("../assets/images/ForgotPassword.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100%;
}

.forgotDialog-box {
    width: 60%;
    /* height: 80%; */
    margin: auto;
    background-color: #ffffff;
    display: block !important;
    justify-content: center;
    padding: 2.142857142857143rem 2.142857142857143rem 2.142857142857143rem;
}

.perfect_boxA {
    padding-bottom: 1.4285714285714286rem;
}

.account .forgot_txt {
    font-size: 1.2857142857142858rem;
}

.login-content {
    width: 65%;
    margin: auto;
    margin-top: 11%;
    /* margin-top: -30%; */
}

.popTitle {
    height: 3.142857142857143rem;
    width: 20rem;
    margin: auto;
}

.popTitletwo {
    width: 100%;
    margin: auto;
    text-align: center;
}

.popTitletwo-text {
    font-size: 2.5714285714285716rem;
    color: #1a2332;
    line-height: 2.857142857142857rem;
    font-family: 'DMSans Bold';
}

.popTitle-head {
    font-size: 1.2857142857142858rem;
    color: #1a2332;
    line-height: 1.4285714285714286rem;
    margin-top: 2.142857142857143rem;
    margin-bottom: 0.7142857142857143rem;
}

.popTitle-img {
    height: auto;
    margin-bottom: 2.142857142857143rem;
}

.popTitle img {
    width: 100%;
    height: 100%;
}

.forgot_txt {
    font-size: 1.2857142857142858rem;
    color: #1290c9;
    line-height: 1.1428571428571428rem;
    text-decoration: underline;
    text-align: right;
    margin-bottom: 2.142857142857143rem;
    position: relative;
    z-index: 10;
    cursor: pointer;
}

.btn_form_log {
    width: 43%;
    height: 4.142857142857143rem;
    line-height: 2.142857142857143rem;
    background: #cccccc;
    border-radius: 1rem;
    font-size: 1.2857142857142858rem;
    color: #ffffff;
    text-align: center;
}

.el-button:focus,
.el-button:hover {
    background: #1290c9;
    border: none;
}

.el-button:active {
    color: #ffffff;
}

.blue {
    background: #1290c9 !important;
}

.log_check .el-checkbox {
    display: flex;
    white-space: normal;
}

.dialog_cont /deep/.el-input__inner {
    border: none;
    background: #ffffff;
    border-radius: 0.7142857142857143rem;
    border: 0.07142857142857142rem solid #8497ab;
    padding-left: 1.4285714285714286rem;
    font-size: 1.2857142857142858rem;
    color: #022955;
}

.dialog_cont /deep/ .el-form-item__content {
    line-height: 2.142857142857143rem;
}

.dialog_cont /deep/ .el-form-item__label {
    font-size: 1.2857142857142858rem !important;
    color: #1a2332;
}

/deep/ .el-checkbox__label {
    font-size: 1.1428575rem;
    color: #1a2332;
    line-height: 1.2857142857142858rem;
}

/deep/ .el-checkbox__inner {
    border-radius: 0.2857142857142857rem;
    width: 1.4285714285714286rem;
    height: 1.4285714285714286rem;
}

.log_check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.log_check /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}

.log_check /deep/ .el-checkbox__inner::after {
    width: 1rem;
    height: 1rem;
    left: 0.14285714285714285rem;
    top: 0.21428571428571427rem;
    background-image: url(../assets/img/check_ic.png);
    background-size: 1rem 0.7142857142857143rem;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}

.log_check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
    transform: rotate(0deg) scaleY(1);
}

.log_check /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: inherit;
}

.log_check /deep/ .el-checkbox__label {
    font-size: 1.1428571428571428rem;
}

.img-left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.7142857142857143rem;
    height: 6.428571428571429rem;
    width: 6.428571428571429rem;
    border-radius: 50%;
    border: 0.07142857142857142rem solid #999999;
}

.active_item {
    border: 0.07142857142857142rem solid #1A2332;
}

.prtfect_item .img-left img {
    display: flex;
    width: 3.7857142857142856rem;
    height: 3.7857142857142856rem;
}

.prtfect_item:last-child .img-left img {
    display: flex;
    width: 4rem;
    height: 4rem;
}

.prtfect_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 10.714285714285714rem; */
    margin-right: 6%;
}

.prtfect_item:last-child {
    margin-right: 0;
}

.prtfect_item_desc {
    height: 1.4285714285714286rem;
    line-height: 1.4285714285714286rem;
    font-size: 1.2857142857142858rem;
    color: #666666;
}

.active_desc {
    color: #1A2332;
}

/deep/ .el-select .el-input {
    width: 100%;
}

/deep/ .input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.elselect /deep/ .el-select {
    background-color: #fff;
    border-radius: 0.7142857142857143rem;
    border: 0.07142857142857142rem solid #8497ab;
    /* padding-left: 1.4285714285714286rem; */
    font-size: 1.2857142857142858rem;
    color: #022955;
    border-right: transparent;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    width: 10.714285714285714rem;
}

.elselect /deep/ .el-input__inner {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-left: transparent;
}

::v-deep .el-button {
    border: none;
}

::v-deep .el-button:hover {
    color: #ffffff;
}
</style>
