<template>
    <!--srcAppvuehtmlStart-->

    <div id="defaultId1" class="   ucHDVhv  ">

        <router-view id="idQvSVuS">
        </router-view>
        <div id="g441c3" class="  ue6578 ueIA7DU uaBPAwJ  uVKKkgA">
            <div id="g9fb19">公共样式配置
            </div>
            <div id="gcfc5a" class="      scrollbar">
                <div id="gbdcbe">文字
                </div>
                <div id="g0ab33">文字
                </div>
                <div id="gef142">文字
                </div>
                <div id="gd4935">文字
                </div>
                <div id="gef730">文字
                </div>
                <div id="g03a3e">文字
                </div>
                <div id="g9fe4f">文字
                </div>
                <div id="g0974f">文字
                </div>
                <div id="g8de57">文字
                </div>
                <div id="g81ba5">文字
                </div>
                scrollbar滚动条样式

            </div>
        </div>
        <Perfectdata @startTime="startTime" :loginAndCreatePop.sync="Perfectdatalog"></Perfectdata>
        <!-- <ReLogin :reLoginPop="reLoginVis"></ReLogin> -->
    </div>
    <!--srcAppvuehtmlEnd-->

</template>

<script>
import Perfectdata from "@/components/PerfectData.vue";
// import ReLogin from "@/components/ReLogin.vue"
// interfaceCode

export default {
    name: '',
    props: {

    },
    mounted() {
    document.title = "CSI"; // 设置页面标题
    },
    components: {
        Perfectdata,
        // ReLogin
    },
    data() {
        return {
            istrue: false, // 用于存储某个需要判断的字段
            Perfectdatalog: false,
            timer: null,       // 定时器
            token: localStorage.getItem("token"),
            authCode: localStorage.getItem("authCode"),
            reLoginVis: false,
            // insertData
            // srcAppvueDataStart

            // srcAppvueDataEnd

        }
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.startTimer();

                } else {
                    clearInterval(this.timer);
                }
            },
            immediate: true,
        },
    },
    methods: {
        startTime(value) {
            clearInterval(this.timer); // 清理定时器
            this.startTimer()
        },
        startTimer() {
            // this.Perfectdatalog = true // 测试
            let certification = JSON.parse(localStorage.getItem('userInfo')).certification;
            if (certification != 1 || localStorage.getItem('renzheng')) {

                this.istrue = true

            } else {
                this.istrue = false
            }
            this.timer = setInterval(() => {
                if (!this.istrue) {
                    this.Perfectdatalog = true; // 显示弹框
                    clearInterval(this.timer); // 清理定时器
                } else {
                    clearInterval(this.timer);
                }
            }, 3600000); // 每小时检查一次
        },
        // insertMethod
        // srcAppvueMethodStart

        // srcAppvueMethodEnd

    },
    computed: {},
    beforeDestroy() {
        clearInterval(this.timer); // 清理定时器
    },
    updated() {
        window.addEventListener("setItem", () => {
            this.token = localStorage.getItem("token");
        });
    },
    

}
</script>
<style>
.srcAppvuecssStart {}

.uVKKkgA {
    display: none;
}

.scrollbar {
    overflow-y: auto;
}

.uVKKkgA .scrollbar {
    height: 100px;
}

.srcAppvuecssEnd {}
</style>